import {db} from '../../firebaseConfig';
import {CALENDAR_COLLECTION, LIVE_COLL, STREAM_COLLECTION, USER_COLLECTION,} from '../../config/constant';
import {getGlobal} from 'reactn';
import {get} from 'lodash';


export const getUserDocRef = (userId) => {
    return db.doc(`${USER_COLLECTION}/${userId}`);
};


export const getLiveDocRef = (eventId) => {
    return db.doc(`${STREAM_COLLECTION}/${eventId}`);
};

export const getPastVideosCollRef = (eventId) => {
    return db.collection(`${STREAM_COLLECTION}/${eventId}/pastVideos`);
};

export const getStreamDocRef = (eventId, streamId) => {
    return db.doc(`${STREAM_COLLECTION}/${eventId}/streams/${streamId}`);
};


export const getRoomsCollRef = (eventId) => {
    return db.collection(`${STREAM_COLLECTION}/${eventId}/rooms`);
};

export const getRoomsDocRef = (eventId, roomId) => {
    return db.doc(`${STREAM_COLLECTION}/${eventId}/rooms/${roomId}`);
};


export const getReceiveGiftColl = (eventId, streamId) => {
    return db.collection(LIVE_COLL)
        .doc(eventId)
        .collection('streams')
        .doc(streamId)
        .collection('gifts')
}


export const myPrjGECollRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.collection(`${CALENDAR_COLLECTION}/${user_id}/projects`)
}


export const myCal = () => {
    const uid = getGlobal()?.user?.user_id
    if (!uid) {
        return;
    }
    return db.collection(CALENDAR_COLLECTION).doc(uid)
}


export const myUserDocRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${USER_COLLECTION}/${user_id}`)
}

export const updateUserCollectionRef = (updatedAt) => {
    const user_id = get(getGlobal(), 'user.user_id')
    if (!user_id) return
    return db.collection(CALENDAR_COLLECTION).doc(user_id)
        .set({updatedAt: updatedAt}, {merge: true})
}
