import {IS_PRODUCTION} from '../config/constant';


export const products = IS_PRODUCTION ?
    [
        {
            name: "HXプロフェッショナル",
            id: "prod_Kp7AHLTSapUz5E",
            price: {
                month: "price_1K9SwUKtz9s4YUGtqrjodzI9",
                year: "price_1K9SwUKtz9s4YUGti4AR1yYh"
            },
            used: ['prod_Kp7AHLTSapUz5E']
        },
        {
            name: "HXスペシャリスト",
            id: "prod_Kp7BMBp6j67aQC",
            price: {
                month: "price_1K9SwhKtz9s4YUGtJM8ro5ug",
                year: "price_1K9SwgKtz9s4YUGtfulWSaFc"
            },
            used: ['prod_Kp7AHLTSapUz5E', 'prod_Kp7BMBp6j67aQC']

        },
        {
            name: "HXエキスパート",
            id: "prod_Kp7BmTB7K1vSpO",
            price: {
                month: "price_1K9SwqKtz9s4YUGtp3VEGwGU",
                year: "price_1K9SwqKtz9s4YUGtbJ82izIC"
            },
            used: ['prod_Kp7AHLTSapUz5E', 'prod_Kp7BMBp6j67aQC', 'prod_Kp7BmTB7K1vSpO']
        },
    ]
    :
    [
        {
            name: "HXプロフェッショナル",
            id: "prod_Kp0lgoc1ypEZcs",
            price: {
                month: "price_1K9WorKtz9s4YUGtx3zi4OHE",
                year: "price_1K9MjWKtz9s4YUGt68qRQ4Y8"
            },
            used: ['prod_Kp0lgoc1ypEZcs']
        },
        {
            name: "HXスペシャリスト",
            id: "prod_Kp1doQSIKZVWsp",
            price: {
                month: "price_1K9NZuKtz9s4YUGtl3JBNAtX",
                year: "price_1K9NZuKtz9s4YUGtj5zdKc8E"
            },
            used: ['prod_Kp0lgoc1ypEZcs', 'prod_Kp1doQSIKZVWsp']
        },
        {
            name: "HXエキスパート",
            id: "prod_Kp1esAcpRs85yb",
            price: {
                month: "price_1K9NatKtz9s4YUGthUokiJtb",
                year: "price_1K9NatKtz9s4YUGt0Zm00d8n"
            },
            used: ['prod_Kp0lgoc1ypEZcs', 'prod_Kp1doQSIKZVWsp', 'prod_Kp1esAcpRs85yb']
        },
    ]

export const productsId = IS_PRODUCTION ?
    [
        "prod_Kp7AHLTSapUz5E",
        "prod_Kp7BMBp6j67aQC",
        "prod_Kp7BmTB7K1vSpO",

    ]
    :
    [
        "prod_Kp0lgoc1ypEZcs",
        "prod_Kp1doQSIKZVWsp",
        "prod_Kp1esAcpRs85yb",
    ]
