import {useEffect} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {myUserDocRef} from '../../firebaseRef/useRef';

export const useCordemiaDate = () => {
    const [myUser] = useDocumentData(myUserDocRef())
    // const [, setDate] = useGlobal('date');

    useEffect(() => {
        if (myUser) {
            // let newDate = myUser?.cordemiaDate
            // if (newDate) {
            //     setDate(newDate)
            // }
        }
        // else {
        //     console.log("set date")
        //     setDate(new Date())
        // }

        // eslint-disable-next-line
    }, [myUser])

}
