import moment from 'moment';
import {maxBy, minBy} from 'lodash/math';


export const getStartEnd = (dates) => {
    if (!dates?.length)
        return {
            start: moment().subtract(1, "days").startOf("days").format(),
            end: moment().add(14, "days").endOf("days").format()
        }

    let start, end
    start = moment(minBy(dates, 'date').date).subtract(1, "days").format()
    end = moment(maxBy(dates, 'date').date).add(1, "days").format()

    return {start, end}
}
