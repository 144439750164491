import {findIndex} from 'lodash';
import {getGoogleIdentities} from './getGoogleIdentities';

export const getIdentityColor = (colorId) => {
    let googleIdentities = getGoogleIdentities()

    if (!colorId || !googleIdentities?.length)
        return null

    let index = findIndex(googleIdentities, {id: colorId})

    if (index !== -1) {
        return googleIdentities[index]?.color || ''
    }
    return null
}
