//helpers
const extendedProps = event => {
    return event?.extendedProps
}
const isMileStone = event => {
    return !!extendedProps(event)?.isMileStone
}
const isMilestoneHide = event => {
    return !!extendedProps(event)?.isMilestoneHide
}
const isEventVote = event => {
    return !!extendedProps(event)?.isEventVote
}
const isMilestoneNextMonth = event => {
    return !!extendedProps(event)?.isMilestoneNextMonth
}
const isSuccess = event => {
    return !!extendedProps(event)?.isSuccess
}
const conferenceData = event => {
    return extendedProps(event)?.conferenceData
}
const conferenceDataStartUrl = event => {
    const cData = event?.conferenceData || conferenceData(event)
    return cData?.start_url
        || cData?.entryPoints?.[0]?.uri
}

const conferenceDataJoinUrl = event => {
    const cData = event?.conferenceData || conferenceData(event)
    return cData?.join_url || cData?.entryPoints?.[0]?.uri
}

const task = event => {
    return extendedProps(event)?.task
}
const isAllDay = event => {
    return event?.allDay
}
const isHoliday = event => {
    return event?.holiday
}
const publicIcon = (iconName) => {
    return `${process.env.PUBLIC_URL}/icons/${iconName}`
}
const isAccepted = event => {
    return extendedProps(event)?.isAccepted
}

export {
    extendedProps,
    isMileStone,
    isMilestoneHide,
    isMilestoneNextMonth,
    isEventVote,
    isSuccess,
    conferenceData,
    conferenceDataStartUrl,
    conferenceDataJoinUrl,
    task,
    isAllDay,
    isHoliday,
    publicIcon,
    isAccepted,
}
