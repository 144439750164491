import {filter} from 'lodash';
import {getGlobal} from 'reactn';

export const getGoogleIdentities = () => {
    const {GoogleColor, calendarUser} = getGlobal()
    const identitySettings = calendarUser?.identitySettings || []
    if (identitySettings?.length) {
        return filter(identitySettings, i => !i?.isDeleted && !i?.isArchive)
    }

    return GoogleColor.map((color, index) => {
        return {
            id: index + 1,
            color,
            name: "",
            isArchive: false,
            isDeleted: false
        }
    })
}
