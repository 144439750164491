import {useGlobal} from 'reactn';
import {ggTokenChecked} from '../config/auth';
import store from 'store';
import {remove} from 'lodash';
import {findIndex} from 'lodash/array';
import {useEffect} from 'react';
import {useSnapshot} from 'valtio';
import {projectsProxy} from '../valtio/proxy/projects.proxy';

export const useMyGGProjects = () => {
    const [googleCalendarInit] = useGlobal('googleCalendarInit');
    const [calendarUser] = useGlobal('calendarUser')
    const [googleStatus] = useGlobal('googleStatus')
    const {setGoogleProjects} = useSnapshot(projectsProxy)

    const getGoogleProjects = async () => {
        try {
            const ggToken = await ggTokenChecked()
            if (googleCalendarInit && calendarUser?.listGgCalenviewShow?.length !== 0 && ggToken && ggToken.length !== 0) {
                window.gapi.client.calendar.calendarList.list().then(({result}) => {
                    const data = result.items || [];
                    let listShow = calendarUser?.listGgCalenviewShow || []
                    remove(data, g => {
                        return g?.id?.includes('#contacts@group')
                            || g?.accessRole === 'freeBusyReader'
                            || g?.id?.includes('#holiday@group');
                    });

                    let projectGG = data.map(d => {
                        let idx = findIndex(listShow, l => l.id === d.id)
                        if (idx !== -1) {
                            d.selected = listShow[idx]?.check || false
                        } else {
                            d.selected = false
                        }

                        return {
                            ...d,
                            is_google: true
                        }
                    }).filter(f => f.selected)

                    store.set("GGList", projectGG)
                    setGoogleProjects(projectGG)
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (googleStatus?.is_login && googleCalendarInit && calendarUser?.listGgCalenviewShow?.length !== 0) {
            getGoogleProjects().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [googleStatus, googleCalendarInit, calendarUser])

}
