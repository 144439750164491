import {useEffect} from 'react';
import {useGlobal} from 'reactn';
import {snapshot} from 'valtio/vanilla';
import {projectsProxy} from '../valtio/proxy/projects.proxy';
import {useMyGEProjects} from './useGeniamProjects';
import {useMyGGProjects} from './useGoogleProjects';
import {useCalendarUser, useMyGEIdentity, useMyGETask, useMyUserData, useSubsCoins} from '../common/user';
import {useZoomData} from '../common/actions/zoom';
import {useCordemiaDate} from '../common/use/user/useCordemiaDate';
import {useCoins} from './useCoins';
import {useGems} from './useGems';
import {useGeniamEvents} from './useGeniamEvents';
import {useGoogleEvents} from './useGoogleEvents';
import {ggTokenChecked} from '../config/auth';


export const useUserData = () => {
    const [user] = useGlobal('user');
    const [googleCalendarInit] = useGlobal('googleCalendarInit');
    const [, setGroups] = useGlobal('groups')
    const {geniamProjects} = snapshot(projectsProxy)
    const [calendarUser] = useGlobal('calendarUser')

    useMyGEProjects()
    useMyGGProjects()
    useMyGEIdentity()
    useMyGETask()
    useCalendarUser()
    useMyUserData()
    useSubsCoins()
    useZoomData()
    useCordemiaDate()
    useCoins()
    useGems()
    useGeniamEvents()
    useGoogleEvents()

    useEffect(() => {
        let group = [];
        geniamProjects.forEach(project => {
            if (!project.is_sync && project.selected) {
                group.push(project)
            }
        })
        setGroups(group)

        // eslint-disable-next-line
    }, [geniamProjects])


    useEffect(() => {
        if (googleCalendarInit && calendarUser?.googleAuth) {
            ggTokenChecked().then();
        }
        // eslint-disable-next-line
    }, [calendarUser, user, googleCalendarInit]);
}
