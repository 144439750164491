import momentTz from "moment-timezone";
import getUserTimeZone from "./getUserTimeZone";
import moment from "moment";
import {toDate} from './date';

export const zoneHHmm = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format("HH:mm")
    return momentTz.tz(time, getUserTimeZone()).format("HH:mm")
}
export const zoneMM = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format("mm")
    return momentTz.tz(time, getUserTimeZone()).format("mm")
}

export const zoneDateTime = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format('YYYY-MM-DD HH:mm')
    return momentTz.tz(time, getUserTimeZone()).format("YYYY-MM-DD HH:mm")
}

export const zoneDatedd = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format('dd')
    return momentTz.tz(time, getUserTimeZone()).format("dd")
}

export const zoneDate = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format("YYYY-MM-DD")
    return momentTz.tz(time, getUserTimeZone()).format("YYYY-MM-DD")
}
export const zoneDates = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format()
    return momentTz.tz(time, getUserTimeZone()).format()
}

export const zoneCustomFormat = (time, format, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format(format)
    return momentTz.tz(time, getUserTimeZone()).format(format)
}

export const zoneObj = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local()

    return momentTz.tz(time, getUserTimeZone())
}

export const dateStartOf = (time) => {
    if (!time) return;
    return moment(time).startOf("day").format('YYYY-MM-DD')
}
export const dateEndOf = (time) => {
    if (!time) return;
    return moment(time).endOf("day").format('YYYY-MM-DD')
}

export const dateEndOfAdd = (time) => {
    if (!time) return;
    return moment(time).endOf("day").add(1, "day").format('YYYY-MM-DD')
}

export const dateAdd = (time, hour) => {
    if (!time || !hour) return;
    return moment(time).add(hour, "hour").format()
}
export const dateDiff = (start, end, time) => {
    if (!start || !end || !time) return
    return moment(end).diff(start, time, true)
}

export const dateHour = (time, hour) => {
    if (!time || !hour) return
    return moment(time).hour(hour).format()
}

export const formatTz = (time) => {
    return moment(time).tz(getUserTimeZone()).format('Z')
}


export const removeDateBeforeNow = (times = [], thisTime = null) => {
    // times = [{date: "YYYY-MM-DD HH:mm", timeStart: 'HH:mm'. timeEnd: 'HH:mm'}]
    if (!times?.length) return
    let defaultTime = toDate(moment.now());
    if (moment(thisTime).isValid()) defaultTime = thisTime
    let compareTimeCheckByNow = false
    if (new Date().getTime() < new Date(defaultTime).getTime()) compareTimeCheckByNow = true
    return times.filter(e => {
            let cloneDefaultTimeStart = setTimeMoment(moment(new Date()).format('YYYY-MM-DD HH:mm'),
                ['hour', 'minute'],
                [
                    moment(e.timeStart, 'HH:mm').format('HH'),
                    moment(e.timeStart, 'HH:mm').format('mm')
                ])
            let cloneDefaultTimeEnd = setTimeMoment(moment(new Date()).format('YYYY-MM-DD HH:mm'),
                ['hour', 'minute'],
                [
                    moment(e.timeEnd, 'HH:mm').format('HH'),
                    moment(e.timeEnd, 'HH:mm').format('mm')
                ])
            if (compareTimeCheckByNow) {
                return Boolean(checkDiffDaySameDate(new Date(e.date), defaultTime, 'same', 'day', false, 'YYYY-MM-DD')
                    &&
                    (
                        Boolean(new Date(moment(defaultTime).format()).getTime() <= new Date(e.date).getTime())
                    ))
            } else {
                return Boolean(checkDiffDaySameDate(new Date(e.date), new Date(), 'same', 'day', false, 'YYYY-MM-DD')
                    && (
                        (
                            Boolean(new Date().getTime() >= new Date(cloneDefaultTimeStart).getTime())
                            && Boolean(new Date().getTime() <= new Date(cloneDefaultTimeEnd).getTime())
                        )
                        ||
                        (
                            Boolean(new Date().getTime() <= new Date(cloneDefaultTimeStart).getTime())
                            && Boolean(new Date().getTime() < new Date(cloneDefaultTimeEnd).getTime())
                        )
                    )
                )
            }
        }
    )
}

export const checkDiffDaySameDate = (timeCheck, thisTime, typeRange, type, isSame = null, format = 'YYYY-MM-DD HH:mm') => {
    timeCheck = toDate(timeCheck)
    thisTime = toDate(thisTime)
    switch (typeRange) {
        case 'after' :
            if (isSame) {
                return moment(timeCheck, format).isSameOrAfter(moment(thisTime, format), type)
            } else {
                return moment(timeCheck, format).isAfter(moment(thisTime, format), type)
            }
        case 'before' :
            if (isSame) {
                return moment(timeCheck, format).isSameOrBefore(moment(thisTime, format), type)
            } else {
                return moment(timeCheck, format).isBefore(moment(thisTime, format), type)
            }
        default :
            return moment(timeCheck, format).isSame(moment(thisTime, format), type)
    }
}

export const checkDateInTimeRange = (time, thisTime = null) => {
    // time = {date: "YYYY-MM-DD HH:mm", timeStart: 'HH:mm'. timeEnd: 'HH:mm'}
    if (!time) return false
    let defaultTime = toDate(moment.now());
    if (thisTime) defaultTime = thisTime
    let cloneDefaultTimeStart = setTimeMoment(moment(new Date()).format('YYYY-MM-DD HH:mm'),
        ['hour', 'minute'],
        [
            moment(time.timeStart, 'HH:mm').format('HH'),
            moment(time.timeStart, 'HH:mm').format('mm')
        ])
    let cloneDefaultTimeEnd = setTimeMoment(moment(new Date()).format('YYYY-MM-DD HH:mm'),
        ['hour', 'minute'],
        [
            moment(time.timeEnd, 'HH:mm').format('HH'),
            moment(time.timeEnd, 'HH:mm').format('mm')
        ])
    return Boolean(checkDiffDaySameDate(new Date(time.date), new Date(), 'same', 'day', false, 'YYYY-MM-DD')
        && Boolean(new Date(cloneDefaultTimeStart).getTime() <= new Date(defaultTime).getTime())
        && Boolean(new Date(cloneDefaultTimeEnd).getTime() >= new Date(defaultTime).getTime())
    )
}

export const setTimeMoment = (time, type, value, format = 'YYYY-MM-DD HH:mm') => {
    if (!type?.length) return
    if (!value?.length) return
    let data = {}
    type.map((e, idx) => {
        return Object.assign(data, {[e]: value[idx]})
    })

    return moment(time).set(data).format(format)
}
