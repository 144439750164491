import axios from 'axios';
import moment from 'moment';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import {useEffect} from 'react';
import {isEmpty} from 'lodash';
import qs from 'query-string';
import {useHistory} from 'react-router-dom';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {msgError, msgSuccess} from '../msg';
import {conferenceDataJoinUrl} from '../../components/handlers/infoEvent';
import {API_ZOOM, ZOOM_COLLECTION, ZOOM_REDIRECT_OAUTH} from '../../config/constant';
import Fire from '../../firebaseConfig';
import {myZoomDocRef} from '../firebaseRef/Zoom';

const jwt = require('jsonwebtoken');

const clientId = process.env.REACT_APP_ENV === 'production' ? "MTYfIbJeTNyHKKV7j7L98A" : "lUCopqPS2myRN6S4qHKtg";
export const handleConnectZoomOAuth = async () => {
    try {
        const redirect_uri = process.env.REACT_APP_ENV === 'production' ? 'https://calendar.geniam.com' : 'https://calendar-stg.geniam.com';
        window.open(
            `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}`,
            "zoomLogin",
            "width=700,height=600,top=150,left=200"
        )
    } catch (e) {
        console.log(e);
    }
};

export const handleCreateZoomMeeting = async (type, event, title, zoneUserShare) => {
    try {
        const zoomOAuthDocRef = Fire.firestore().doc(ZOOM_COLLECTION + `/${event?.user}`);
        const snapshot = await zoomOAuthDocRef.get();
        if (!snapshot.exists)
            return null;

        const barrierToken = jwt.sign({
            username: event?.user,
            exp: Math.floor(Date.now() / 1000) + 3600
        }, 'shhhhh');

        const {data} = await axios.post(API_ZOOM + '/create', {
            type: type,
            timeStart: event?.start,
            timeEnd: event?.end,
            schedule: {
                ...event,
                service_name: "Calendar meetings",
                title: title,
            },
            timeZone: zoneUserShare
        }, {
            'headers': {
                'Authorization': `Bearer ${barrierToken}`
            }
        });
        return data
    } catch (e) {
        console.log(e);
        return null
    }
};

export const handleUpdateZoomMeeting = async (startTime, endTime, zoomLink, userId, title) => {
    try {
        const zoomOAuthDocRef = Fire.firestore().doc(ZOOM_COLLECTION + `/${userId}`);
        const snapshot = await zoomOAuthDocRef.get();
        if (!snapshot.exists)
            return null;

        const barrierToken = jwt.sign({
            username: userId,
            uid: userId,
            exp: Math.floor(Date.now() / 1000) + (60 * 5)
        }, 'shhhhh');

        let meetingId = zoomLink.substring(zoomLink.lastIndexOf('/') + 1, zoomLink.lastIndexOf('?'));

        await axios.post(API_ZOOM + '/update', {
            type: 'meetings',
            startTime,
            endTime,
            meetingId: `${meetingId}`,
            userId,
            title
        }, {
            'headers': {
                'Authorization': `Bearer ${barrierToken}`
            }
        })
    } catch (e) {
        console.log(e);
    }
};

export const deleteZoom = async (zoomId) => {
    if (!zoomId)
        return;
    try {
        await axios.post(API_ZOOM + '/delete', {
            type: 'meetings',
            meetingId: zoomId,
        })
    } catch (e) {
        console.log(e)
    }
}

export const createZoom = async (event) => {
    const {user} = getGlobal();
    if (!user?.user_id)
        return null;
    try {
        const {data} = await axios.post(API_ZOOM + '/create', {
            type: 'meetings',
            timeStart: moment(event.start).format(),
            timeEnd: moment(event.end).format(),
            schedule: {
                ...event,
                service_name: 'Calendar Meetings',
                title: event.title,
            },
            timeZone: user?.time_zone || 'Asia/Tokyo'
        });
        if (data)
            return data
        return null
    } catch (e) {
        console.log(e);
        return null
    }
}

export const handleGetValue = (event, callBack) => {
    if (!event)
        return null;
    const linkZoom = conferenceDataJoinUrl(event);

    if (callBack)
        callBack(linkZoom)
    return linkZoom;
}

export const useConnectZoomAuth = (email) => {

    const query = qs.parse(window.location.search);
    const path = window.location.pathname;
    const history = useHistory();
    const [accessToken] = useGlobal('accessToken');

    useEffect(() => {
        if (isEmpty(query))
            return null;
        if (query.showZoomSetting) {
            setGlobal({
                openSetting: true,
                settingTab: 3
            });
            delete query.code;
            history.push(`${path}`);
        }
        if (query.code) {
            getInfoConnected(query, history, path);
        }
        // eslint-disable-next-line
    }, [query?.code, accessToken, email]);

    const getInfoConnected = async (query, history, path) => {
        if (!query.code || !accessToken || !email) {
            return;
        }
        try {
            const {data} = await axios.post(
                API_ZOOM + `/zoomOauth`, {
                    code: query.code,
                    redirect_uri: ZOOM_REDIRECT_OAUTH,
                    user: {
                        email: email
                    }
                });
            if (data) {
                msgSuccess('Connect Zoom OAuth Success')
            }
            window.close();
        } catch (e) {
            console.log(e);
            msgError(e?.response?.data || 'Cannot connect to Zoom! Please try again later')
            window.close();
        } finally {

        }
    };
}

export const useZoomData = () => {
    const [, setZoomOAuth] = useGlobal('zoomOAuth');
    const [zoomData] = useDocumentData(myZoomDocRef())
    useEffect(() => {
        if (zoomData) {
            setZoomOAuth(zoomData)
        }
        // eslint-disable-next-line
    }, [zoomData])
}
