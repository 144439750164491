import {findIndex} from 'lodash';
import {snapshot} from 'valtio/vanilla';
import {projectsProxy} from '../../valtio/proxy/projects.proxy';

export const getProjectColor = (projectId) => {
    const {geniamProjects} = snapshot(projectsProxy)

    if (!projectId || !geniamProjects?.length)
        return null

    let index = findIndex(geniamProjects, {uuid: projectId})
    if (index !== -1) {
        return geniamProjects[index]?.color || ''
    }

    return null
}
