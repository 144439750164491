import React, {createContext, useContext, useEffect, useState} from 'react';
import {useGlobal} from 'reactn'
import moment from 'moment';
import {myUserDocRef} from '../common/firebaseRef/useRef';
import {zoneDates} from '../common/moment/format';
import {isMobile} from 'react-device-detect';

moment.locale('ja');

export const DateContext = createContext({})

export const useDates = () => useContext(DateContext)

export const DatesProvider = ({children}) => {
    const value = GenerateDates()
    return (
        <DateContext.Provider value={value}>
            {children}
        </DateContext.Provider>
    )
}

const GenerateDates = () => {
    const [date, setDate] = useGlobal('date');
    const [user] = useGlobal('user');
    const [dates, setDates] = useState([]);
    const range = isMobile ? 3 : 7
    const [changeHourCurrent, setChangeHourCurrent] = useState(0)

    useEffect(() => {
        const runInterval = setInterval(function () {
            if (moment().format("HH:mm:ss") === "00:00:01") {
                setDate(new Date())
                setChangeHourCurrent(changeHourCurrent + 1)
            }
        }, 1000);
        return () => clearInterval(runInterval);
        // eslint-disable-next-line
    }, [changeHourCurrent])

    useEffect(() => {
        initDates()
        // eslint-disable-next-line
    }, [date, changeHourCurrent]);

    const initDates = () => {
        let startDate = moment(date).format();
        let today = moment().format('YYYY-MM-DD');
        let endDate = moment(date).add(range, 'day').format();
        let arr = [];
        for (let i = 0; i < range; i++) {
            let dateNow = moment(startDate).add(i, 'day').format('YYYY-MM-DD');
            let month = moment(dateNow).month() + 1;
            let color = today === dateNow ? '#4CE157' : (moment(dateNow).weekday() === 0 ? '#DB000F' : 'black');
            let generateEvent = {
                month: month,
                date: dateNow,
                dayOfWeek: moment(dateNow).weekday(),
                num: moment(dateNow).date(),
                text: moment(dateNow).format('dd'),
                checked: moment(today).isBetween(startDate, endDate, null, '[]')
                    ? !OverTime(dateNow)
                    : true,
                color,
                isSunday: moment(dateNow).weekday() === 0
            }
            arr.push(generateEvent);
        }

        setDates(arr);
    }

    const OverTime = val => {
        return moment(val) < moment().startOf('day')
    };

    const onPrev = async () => {
        if (user?.user_id) {
            document.getElementById('scrollContent').scrollIntoView()
            const userRef = myUserDocRef()
            let datePrev = zoneDates(new Date(moment(date).subtract(range, 'day').format()))
            await userRef.set({
                cordemiaDate: datePrev
            }, {merge: true})
        } else {
            document.getElementById('scrollContent').scrollIntoView()
            setDate(new Date(moment(date).subtract(range, 'day').format()));
        }
    };

    const onNext = async () => {
        if (user?.user_id) {
            document.getElementById('scrollContent').scrollIntoView()
            const userRef = myUserDocRef()
            let dateNext = zoneDates(new Date(moment(date).add(range, 'day').format()))
            setDate(dateNext)
            await userRef.set({
                cordemiaDate: dateNext
            }, {merge: true})
        } else {
            document.getElementById('scrollContent').scrollIntoView()
            setDate(new Date(moment(date).add(range, 'day').format()));
        }
    };

    const onToday = async () => {
        if (user?.user_id) {
            const userRef = myUserDocRef()
            await userRef.set({
                cordemiaDate: new Date()
            }, {merge: true})
        } else {
            setDate(new Date())
        }
    }

    const toggleDate = index => {
        if (dates[index]) {
            dates[index] = {...dates[index], checked: !dates[index].checked};
            setDates([...dates]);
        }
    };

    return {
        onPrev,
        onNext,
        onToday,
        toggleDate,
        dates,
        setDates,
    }
}
