import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import axiosConfig from './config/axios';
import './globalData';
import './firebaseConfig';
import {StripeProvider} from 'react-stripe-elements';
import GoogleAuth from './components/Calendar/google/auth';
import {MyThemeProvider} from './context/ThemeContext';
import {STRIPE_PUBLISH_KEY} from './config/constant';
import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';

axiosConfig();
GoogleAuth();

createRoot(document.getElementById('root')).render(
    <>
        <MyThemeProvider>
            <BrowserRouter history={createBrowserHistory()}>
                <StripeProvider apiKey={STRIPE_PUBLISH_KEY}>
                    <App/>
                </StripeProvider>
            </BrowserRouter>
        </MyThemeProvider>
    </>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals();
