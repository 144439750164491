import {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {fetchRefreshToken} from '../components/actions/user';
import {auth} from '../firebaseConfig';
import {useCollection, useDocumentData} from 'react-firebase-hooks/firestore';
import {filter} from 'lodash';
import {getReceiveGiftColl, getUserDocRef, myCal, myUserDocRef} from './firebaseRef/useRef';
import axios from 'axios';
import {API_GENIAM} from '../config/apiUrl';
import store from 'store';

export const signInFirebase = async () => {
    try {
        const data = await fetchRefreshToken();
        if (!data?.firebaseToken)
            return;

        await auth.signInWithCustomToken(data.firebaseToken);
    } catch (e) {
        console.log(e);
    }
};

// export const useMyGEProjects = () => {
//     const [projects, setProjects] = useGlobal('projects');
//     const {setGeniamProjects, geniamProjects} = useSnapshot(projectsProxy)
//     const deferredGeniamProjects = useDeferredValue(geniamProjects)
//     const [list] = useCollection(myPrjGECollRef().where("is_deleted", "==", false))
//
//     useEffect(() => {
//         if (list) {
//             let data = list.docs.map(pro => ({
//                 ...pro.data(),
//                 id: pro.id,
//                 msSelected: pro.data().msSelected !== undefined ? pro.data().msSelected : true,
//                 apSelected: pro.data().apSelected !== undefined ? pro.data().apSelected : true,
//             }));
//             remove(data, d => d?.is_sync || d?.is_deleted)
//             setProjects([...data])
//             setGeniamProjects(data)
//         }
//         // eslint-disable-next-line
//     }, [list])
//
//     return [projects]
// }

export const useMyGEIdentity = () => {
    const [calendarUser] = useGlobal('calendarUser')
    const [GoogleColor] = useGlobal("GoogleColor")
    const [, setGoogleIdentities] = useGlobal('googleIdentities')

    useEffect(() => {
        const identitySettings = calendarUser?.identitySettings || []
        if (identitySettings?.length) {
            setGoogleIdentities(filter(identitySettings, i => !i.isDeleted && !i.isArchive))
            return
        }
        const nextData = GoogleColor.map((color, index) => {
            return {
                id: index + 1,
                color,
                name: "",
                isArchive: false,
                isDeleted: false
            }
        })
        setGoogleIdentities(nextData)
        // eslint-disable-next-line
    }, [calendarUser])


}

export const useMyGETask = () => {
    const [calendarUser] = useGlobal('calendarUser')
    const [, setTasks] = useGlobal('tasks')

    useEffect(() => {
        if (!calendarUser) return;
        const {tasks = {}} = calendarUser
        setTasks(tasks)
        // eslint-disable-next-line
    }, [calendarUser])
}

// export const useGoogleCalendarList = () => {
//     const [googleCalendarInit] = useGlobal('googleCalendarInit');
//     const [calendarUser] = useGlobal('calendarUser')
//     const [googleCalendars, setGoogleCalendars] = useGlobal('googleCalendars')
//
//     const getProjectGG = async () => {
//         await ggTokenChecked()
//         const ggToken = store.get("ggToken")
//
//         if (googleCalendarInit && calendarUser && ggToken && ggToken.length !== 0) {
//             await window.gapi.client.calendar.calendarList.list().then(({result}) => {
//                 const data = result.items || [];
//                 let listShow = calendarUser?.listGgCalenviewShow || []
//                 remove(data, g => {
//                     return g.id.includes('#contacts@group')
//                         || g.accessRole === 'freeBusyReader'
//                         || g.id.includes('#holiday@group');
//                 });
//                 let projectGG = data.map(d => {
//                     let idx = findIndex(listShow, l => l.id === d.id)
//                     if (idx !== -1) {
//                         d.selected = listShow[idx]?.check || false
//                     } else {
//                         d.selected = false
//                     }
//                     return {
//                         ...d,
//                         is_google: true
//                     }
//                 }).filter(f => f.selected)
//                 store.set("GGList", projectGG)
//                 setGoogleCalendars(projectGG)
//             }).catch(console.log);
//         }
//     }
//
//     useEffect(() => {
//         getProjectGG()
//         // eslint-disable-next-line
//     }, [googleCalendarInit, calendarUser])
//
//     return {googleCalendars}
// }

export const useCalendarUser = () => {
    const [calendarUser, setCalendarUser] = useGlobal('calendarUser')
    const [value, loading, error] = useDocumentData(myCal())
    const [ggStatus, setGGStatus] = useGlobal('googleStatus')

    useEffect(() => {
        if (loading || error || (!loading && !value))
            return;

        setCalendarUser({...value})
        if (value?.googleAuth) {
            store.set("googleAuth", value.googleAuth)
            let newStatus = {...ggStatus}
            if ((!newStatus.is_login || newStatus.userProfile?.email !== value.googleAuth.email)) {
                newStatus.is_login = true
                newStatus.userProfile = value.googleAuth
                setGGStatus({...newStatus})
            }
        }
        // eslint-disable-next-line
    }, [value, loading, error])

    return {calendarUser}
}

const getAuthorsInfo = async (userIds) => {
    try {
        return await axios.post(`${API_GENIAM}/getGroupUserAvatar`, {
            userIds
        })
    } catch (e) {
        console.log(e);
    }
}

export const useGiftData = (id, streamId) => {
    const [gifts, setGifts] = useState([])
    const [list] = useCollection(id && streamId &&
        getReceiveGiftColl(id, streamId)
            .orderBy('createdAt', "asc"))

    useEffect(() => {
        if (list) {
            let result = list.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));

            getAuthorsInfo(result.map(e => e.userId)).then(({data}) => {
                result = result.map((e) => {
                    data.forEach((i) => {
                        if (e.userId === i.user_id)
                            e.user.avatar = i?.avatar
                    })
                    return e
                })
                setGifts(result)
            })
        }
        // eslint-disable-next-line
    }, [list])

    return {gifts}
}

const cordemiaDefault = {
    cordemiaSetting: {
        showCal: true,
        showSetting: true
    }
}

export const useMyUserData = () => {
    const [, setMyUserData] = useGlobal("myUserData");
    const [myUser] = useDocumentData(myUserDocRef())

    useEffect(() => {
        if (!myUser) {
            setMyUserData(cordemiaDefault)
            return;
        }

        if (myUser && !myUser?.cordemiaSetting) {
            setMyUserData({...myUser, ...cordemiaDefault})
            return;
        }

        setMyUserData({...myUser})
        // eslint-disable-next-line
    }, [myUser])

}

export const useSubsCoins = () => {
    const [user] = useGlobal('user')
    const [, setMyTickets] = useGlobal('myTickets')
    const [myUser, loading] = useDocumentData(getUserDocRef(user?.user_id))


    useEffect(() => {
        if (!myUser && !loading)
            return;

        setMyTickets(myUser?.coins || 0)
        // eslint-disable-next-line
    }, [myUser])

}
