import {proxy} from 'valtio/vanilla';
import {cloneDeep, concat, findIndex, isFunction, remove} from 'lodash';
import {getGlobal} from 'reactn';
import {refetchSource} from '../../common/actions/refetchSourcce';


const eventsProxy = proxy({
    geEvents: {},
    ggEvents: {},

    setGeEvents: (data) => {
        eventsProxy.geEvents = data
        refetchSource('geniam').then()
    },
    setGgEvents: (data) => {
        eventsProxy.ggEvents = data
        refetchSource('google').then()
    },
    addEvent: (event) => {
        if (event.googleEvent) {
            console.log('inher')
            const clonedEvents = cloneDeep(eventsProxy.ggEvents)
            const events = clonedEvents[event.project_uuid]?.events || []
            events.push(event)
            clonedEvents[event.project_uuid] = {
                ...clonedEvents[event.project_uuid],
                events
            }
            eventsProxy.ggEvents = clonedEvents
            refetchSource('google').then()
            return;
        }
        const clonedEvents = cloneDeep(eventsProxy.geEvents)
        const events = clonedEvents[event.project_uuid] || []
        events.push(event)
        clonedEvents[event.project_uuid] = events
        eventsProxy.geEvents = clonedEvents
        refetchSource('geniam').then()
    },
    createEvent: (event, clb = null) => {
        const {googleCalendarList} = getGlobal()
        let _ggEvents = cloneDeep(eventsProxy.ggEvents)
        let _geEvents = cloneDeep(eventsProxy.geEvents)
        const {project_uuid, is_google, googleEvent} = event
        if (is_google || googleEvent) {
            let idx = findIndex(googleCalendarList, {id: project_uuid})
            if (idx === -1)
                return;
            if (_ggEvents[project_uuid] && _ggEvents[project_uuid].events) {
                _ggEvents[project_uuid].events.push(event)
            } else {
                _ggEvents[project_uuid] = {
                    id: project_uuid,
                    events: [event],
                    name: googleCalendarList[idx].summary,
                    color: googleCalendarList[idx].backgroundColor
                }
            }
            eventsProxy.ggEvents = _ggEvents
            refetchSource('google').then()
            return
        }
        if (_geEvents[project_uuid]) {
            _geEvents[project_uuid].events.push(event)
        } else {
            _geEvents[project_uuid] = {
                id: project_uuid,
                events: [event]
            }
        }
        eventsProxy.geEvents = _geEvents
        refetchSource('geniam').then()
    },

    deleteEvent: (event, callback, identity = null) => {
        let _ggEvents = cloneDeep(eventsProxy.ggEvents);
        let _geEvents = cloneDeep(eventsProxy.geEvents);
        let voteEvents = cloneDeep(eventsProxy.voteEvents);
        const {is_google, id, googleEvent} = event;
        const compare = identity ? identity : {id: id}
        if (!event.project_uuid) {
            remove(voteEvents, compare)
            eventsProxy.voteEvents = voteEvents;
            return;
        }
        if (is_google || googleEvent) {
            Object.keys(_ggEvents).forEach(key => {
                if (_ggEvents[key]?.events?.length) {
                    remove(_ggEvents[key].events, compare)

                }
            });
            eventsProxy.ggEvents = _ggEvents;
            if (isFunction(callback)) {
                callback(event)
            }
            return
        }
        Object.keys(_geEvents).forEach(key => {
            if (_geEvents[key]?.events?.length) {
                remove(_geEvents[key].events, compare)

            }
        })
        eventsProxy.geEvents = _geEvents;
        if (isFunction(callback)) {
            callback(event)
        }
    },

    changeProjectGoogleEvent: (eventData, oldProject, newProject) => {
        if (!eventData || !oldProject || !newProject) return;
        const {setGgEvents, ggEvents} = eventsProxy
        let _ggEvents = cloneDeep(ggEvents)
        const events = _ggEvents[oldProject]?.events || []
        const idEvent = eventData.recurringEventId || eventData.id
        const instances = remove(events, e => e.id === idEvent || e.recurringEventId === idEvent)
        const destination = concat(_ggEvents[newProject]?.events || [], instances)
        _ggEvents[oldProject].events = events
        _ggEvents[newProject].events = destination

        setGgEvents(_ggEvents)

    }

})

export {
    eventsProxy
}
