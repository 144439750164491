import {proxy} from 'valtio/vanilla';

const projectsProxy = proxy({
        geniamProjects: [],
        googleProjects: [],
        allProjects: [],
        setGeniamProjects: (list) => {
            projectsProxy.geniamProjects = list
            projectsProxy.allProjects = [
                ...projectsProxy.geniamProjects.map(item => ({...item, is_google: false})),
                ...projectsProxy.googleProjects.map(item => ({...item, is_google: true}))]
                .filter(p => p.selected)
        },
        setGoogleProjects: (list) => {
            projectsProxy.googleProjects = list
            projectsProxy.allProjects = [
                ...projectsProxy.geniamProjects.map(item => ({...item, is_google: false})),
                ...projectsProxy.googleProjects.map(item => ({...item, is_google: true}))]
                .filter(p => p.selected)
        }
    }
)
export {
    projectsProxy
}
