import {getGlobal} from 'reactn'
import {concat, filter, findIndex, uniqBy} from "lodash";
import {CALENDAR_COLLECTION} from '../config/constant';
import {db} from '../firebaseConfig';
import {getEventsInRange} from './getGoogleEvents';
import {snapshot} from 'valtio/vanilla';
import {projectsProxy} from '../valtio/proxy/projects.proxy';

export const getGeniamEvents = (callback) => {
    const {user} = getGlobal()
    if (!user.user_id)
        return null
    return db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
        .where("isDeleted", "==", false)
        .where("isTasks", "==", false)
        .onSnapshot((snapshot) => {
            if (typeof callback === "function")
                callback(snapshot)
        })
}

export const formatGeniamEvents = (event) => {
    const {calendarUser, GoogleColor} = getGlobal();
    const {geniamProjects} = snapshot(projectsProxy)
    let {identitySettings = []} = calendarUser;

    let proIdx = findIndex(geniamProjects, {uuid: event.project_uuid})
    if (proIdx !== -1)
        event.borderColor = geniamProjects[proIdx].color
    if (event?.colorId) {
        if (!identitySettings?.length)
            identitySettings = GoogleColor.map((color, index) => {
                return {
                    id: index + 1,
                    color,
                    name: "",
                    isArchive: false,
                    isDeleted: false
                }
            })
        let colorIdx = findIndex(filter(identitySettings, i => !i.isDeleted && !i.isArchive), {id: event.colorId});
        if (colorIdx !== -1)
            event.backgroundColor = identitySettings[colorIdx].color
    }
    return {...event}
}


export const getSelectedEvents = (events = {}, start, end) => {
    const {geniamProjects} = snapshot(projectsProxy)
    let result = []
    geniamProjects.forEach(pro => {
        if (!pro.selected || pro.is_deleted || pro.is_sync) return
        result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end))
    })
    return uniqBy(result, "id")
}
