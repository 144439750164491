import {useDocumentData} from 'react-firebase-hooks/firestore';
import {useSnapshot} from 'valtio';
import {useEffect} from 'react';
import {gemsProxy} from '../valtio/proxy/gems.proxy';
import {db} from '../firebaseConfig';
import {GEMS_COLLECTION} from '../config/constant';

export const useGems = () => {
    const [gems, loading, error] = useDocumentData(db.doc(`${GEMS_COLLECTION}/rgby`))
    const {setGems} = useSnapshot(gemsProxy)

    useEffect(() => {
        const onGems = () => {
            if (loading)
                return null
            if (error) {
                console.error(error)
                return null
            }
            setGems(gems)
        }

        onGems()

        // eslint-disable-next-line
    }, [gems, loading])

}
