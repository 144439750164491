import React, {createContext, useContext, useEffect, useState} from 'react';
import {getCordemiaCollRef, getStreamCollRef} from '../common/cordemia/cordemiaRef';
import moment from 'moment'
import axios from 'axios';
import {API_GENIAM} from '../config/apiUrl';
import {groupBy, orderBy} from 'lodash/collection';
import {useGlobal} from 'reactn';
import {difference, flatten, remove, uniq,} from 'lodash/array';
import {toDate} from "../common/moment/date";
import {isListChange} from '../common/cordemia/cordemiaContents';
import store from 'store';
import {zoneDates} from '../common/moment/format';

export const StreamsEventsContext = createContext({})

export const useCordemiaList = () => useContext(StreamsEventsContext)


export function StreamsEventsProvider({children}) {

    const value = useCordemiaStreams();
    return <StreamsEventsContext.Provider value={value}>
        {children}
    </StreamsEventsContext.Provider>;
}

export const useCordemiaStreams = () => {
    const [events, setEvents] = useState([])
    const [streams, setStreams] = useState([])
    const [authorUids, setAuthorUids] = useState([])
    const [authors, setAuthors] = useState([])
    const [cordemiaContents] = useGlobal('cordemiaContents')
    const [programsFuture, setProgramsFuture] = useState([])
    const [programsHistory, setProgramsHistory] = useState([])
    const [loadingPrograms, setLoadingPrograms] = useState(true)
    const [eventList, setEventList] = useState(store.get("events") || [])
    const [streamList, setStreamList] = useState(store.get("streams") || [])


    useEffect(() => {
        // if (streams?.length && isListChangeStream(streamList, streams)) {
        if (streams?.length) {
            let newStreams = [...streams]
            store.set("streams", newStreams)
            setStreamList(newStreams)
        }

        // eslint-disable-next-line
    }, [streams, events])

    useEffect(() => {
        if (events?.length && isListChange(eventList, events)) {
            let newEvents = [...events]
            store.set("events", newEvents)
            setEventList(newEvents)
        }

        // eslint-disable-next-line
    }, [events])

    useEffect(() => {
        const sub = getCordemiaCollRef()
            .onSnapshot(snapshot => {
                let evtData = []
                snapshot.docs.forEach(doc => {
                    if (!doc.data()?.title && !doc.data()?.uid)
                        return;
                    let item = {
                        ...doc.data(),
                        id: doc.id,
                        platforms: doc.data()?.platforms?.length ? doc.data()?.platforms : ['cordemia'],
                        contents: doc.data()?.contents || []
                    }
                    if (!item?.platforms?.length || !Boolean(item?.platforms.find(e => e === 'cordemia')))
                        return
                    evtData.push(item)
                })
                setEvents(evtData)

                const authorUid = uniq(evtData.map(e => e.uid))
                // const eventsIds = evts.map(e => e.id)
                setAuthorUids((prevState => {
                    if (prevState?.length === authorUid.length && !difference(prevState, authorUid).length) {
                        return prevState
                    }
                    return authorUid
                }))
            })

        return () => {
            if (sub) sub()
        }
    }, [])

    useEffect(() => {
        if (authorUids?.length) {
            getAuthorsInfo(authorUids).then()
        }

        // eslint-disable-next-line
    }, [authorUids])

    const getContentSelected = (contents) => {
        if (!contents?.length)
            return true

        let checked = false;

        contents.forEach(e => {
            if (cordemiaContents?.includes(e))
                checked = true
        })

        return checked
    }

    useEffect(() => {
        let data = {}
        const newEvents = [...eventList]
        remove(newEvents, e => !getContentSelected(e?.contents))

        const subs = newEvents.map(event => {
            return getStreamCollRef(event.id)
                .onSnapshot(snapshot => {
                    data[event.id] = snapshot.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id,
                        uid: event.uid,
                        eventId: event.id,
                        contents: event?.contents || [],
                        eventName: event?.name || "",
                        product: event?.product || {},
                        eventImage: event?.image || "",
                        descriptions: event.descriptions || event.description,
                        eventTitle: event?.title || "",
                        limitUser: event?.limitUser,
                        moneyMinimum: event?.moneyMinimum
                    }))

                    if (Object.keys(data).length === newEvents.length) {
                        const streamValues = getSingleStreamList(flatten(Object.values(data)))
                        setStreams(streamValues)
                        updateFutureAndHistory(streamValues)
                        setLoadingPrograms(false)
                    }
                })
        })

        return () => {
            subs.forEach(sub => {
                if (sub) sub()
            })
        }

        // eslint-disable-next-line
    }, [eventList, cordemiaContents, events])


    const updateFutureAndHistory = (list) => {
        const _now = moment().utc()
        let arrFuture = []
        const arrHistory = []
        for (let e of list) {
            if (!moment(toDate(e.start)).isValid()) continue
            if (e.recurrence) {
                arrFuture.push(e)
                arrHistory.push(e)
            } else {
                if (toDate(e.start) > _now || (toDate(e.start) < _now && toDate(e.end) > _now)) arrFuture.push(e)
                else arrHistory.push(e)
            }
        }
        setProgramsFuture(groupBy(arrFuture, "eventId"))
        setProgramsHistory(groupBy(arrHistory, "eventId"))
    }

    const getAuthorsInfo = async (userIds) => {
        try {
            const {data} = await axios.post(`${API_GENIAM}/getGroupUserAvatar`, {
                userIds
            })
            setAuthors(data)
        } catch (e) {

        }
    }

    const getSingleStreamList = (streamData) => {
        const newStreams = [...streamData]
        return orderBy(newStreams.map(item => {
            if (!item?.code && !item?.link)
                return []
            if (!item?.times?.length)
                return []
            let checkMany = item?.times?.length > 1
            return item.times.map(time => ({
                ...item,
                id: time.id,
                streamId: item.id,
                // start: moment(toDate(time.timeStart)).format(),
                // end: moment(toDate(time.timeEnd)).format(),
                start: zoneDates(moment(toDate(time.timeStart)).format()),
                end: zoneDates(moment(toDate(time.timeEnd)).format()),
                recurrence: time?.recurrence || '',
                daysOff: time?.daysOff,
                program: time?.recurrence ? "loop" : checkMany ? "many" : "single",
                titleTime: time?.title || ''
            }))
        }).flat(), 'start', 'asc')
    }

    return {
        streams: streamList,
        setStreams: setStreamList,
        events: eventList,
        authors,
        programsFuture,
        programsHistory,
        loadingPrograms
    }
}

export const typeEvent = {
    loop: "loop",
    many: "many",
    single: "single"
}
