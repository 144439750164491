export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
export const IS_DEV = process.env.REACT_APP_ENV === 'dev';
export const IS_STG = process.env.REACT_APP_ENV === 'stg';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const ID_TOKEN_KEY = 'idToken';
export const APP_GLOBAL_ID = "calenview"

export const GENIAM_REFRESH_TOKEN = `ge_${process.env.REACT_APP_ENV}_refreshToken`;
export const USER_COLLECTION = IS_PRODUCTION ? 'users' : 'users-stg';
export const STREAM_COLLECTION = IS_PRODUCTION
    ? 'questumLive'
    : 'questumLive-stg';
export const APPOINTMENT_URL = process.env.REACT_APP_APPOINTMENT_URL

export const CALENDAR_COLLECTION = IS_PRODUCTION ? 'calendar' : 'calendar-stg';
export const GEMS_COLLECTION = IS_PRODUCTION ? 'gems' : 'gems-stg';
export const USER_COLL = IS_PRODUCTION ? "users" : "users-stg"
export const MEETING_COLLECTION = IS_PRODUCTION ? "meetings" : "meetings-stg"
export const QUESTUM_LIVE = IS_PRODUCTION ? "questumLive" : "questumLive-stg"
export const RECOMMEND_COLLECTION = IS_PRODUCTION ? "recommend" : "recommend-stg"
export const REPORT_COLLECTION = IS_PRODUCTION ? "report" : "report-stg"

export const COPYING_EVENT_KEY = 'copyingEvent'
export const USER_INFO_KEY = 'userInfo';
export const REDIRECT_URL_KEY = 'service_redirect_url';

export const CREATED_ACTION = "CREATED"
export const UPDATED_ACTION = "UPDATED"
export const DELETED_ACTION = "DELETED"
export const CHANGE_TIME_ACTION = "CHANGE_TIME"

export const STRIPE_PUBLISH_KEY = IS_PRODUCTION ? 'pk_live_ndRdZMAryciJI126GNjn2wuE' : 'pk_test_P3e8GwkyphGqmyIk5dZgPp6J'

export const METAMOR_COLL = IS_PRODUCTION ? "metamors" : "metamors-stg"
export const LIVE_COLL = IS_PRODUCTION ? "questumLive" : "questumLive-stg"
export const COINS_PACK_COLL = IS_PRODUCTION ? "coins" : "coins-stg"
export const GLOBAL_NAVI_APPS_COLL = IS_PRODUCTION ? "GlobalNavigation" : "GlobalNavigation-stg"

export const APPOINTMENT_DOMAIN = IS_PRODUCTION ? 'https://appy.geniam.com' : 'https://appy-stg.geniam.com'
export const METAMOR_DOMAIN = IS_PRODUCTION ? 'https://metamors.geniam.com' : 'https://metamors-stg.geniam.com'
export const HX_DOMAIN = IS_PRODUCTION ? 'https://hx-cloud.geniam.com' : 'https://hx-cloud-stg.geniam.com'
export const CALENVIEW_DOMAIN = IS_PRODUCTION ? 'https://calenview.geniam.com' : 'https://calenview-stg.geniam.com'

//zoom
export const API_ZOOM = process.env.REACT_APP_API_ZOOM
export const ZOOM_COLLECTION = IS_PRODUCTION ? "metamors" : "metamors-stg"
export const ZOOM_REDIRECT_OAUTH = IS_PRODUCTION ? "https://calendar.geniam.com" : "https://calendar-stg.geniam.com"


export const CREATE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/createTask';
export const DELETE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/deleteTask';


//mail
export const MAIL_TEMPLATES_REPORT_DOC = IS_PRODUCTION ? 'report' : 'report-stg'

//emulators
export const FIRESTORE_EMULATOR_HOST = 'localhost'
export const FIRESTORE_EMULATOR_PORT = 5002
export const STORAGE_EMULATOR_HOST = 'localhost'
export const STORAGE_EMULATOR_PORT = 9199
export const FIREBASE_EMULATOR_UI_DOMAIN = 'http://localhost:4009'
export const USE_EMULATOR = false
