import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import '../index.css';
import 'antd/dist/antd.css';
import "@Andom-Geniam/git-globalnavi/dist/index.css";
import ClfLinearProgress from '../components/Custom/CLFLinearProgress';
import {isEmpty} from 'lodash';

export const MyThemeProvider = ({children}) => {
    const theme = useThemeContext();
    if (isEmpty(theme))
        return <ClfLinearProgress/>;
    return (
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
    );
};

export const useThemeContext = () => {
    const [colors] = useGlobal('colors');
    const [theme, setTheme] = useState({});

    useEffect(() => {
        if (!colors?.primary || !colors)
            return;

        setTheme(createTheme({
            props: {
                primaryColor: colors.primary,
                secondaryColor: '#690003',
                // or add more props to handle theme
                MuiButtonBase: {
                    // The properties to apply
                    // disableRipple: true, // No more ripple, on the whole application 💣!
                },
            },
            transitions: {
                // So we have `transition: none;` everywhere
                create: () => 'none',
            },
        }));
    }, [colors]);

    return theme;
};
