import {db} from '../../firebaseConfig';
import {LIVE_COLL} from '../../config/constant';

export const getCordemiaCollRef = () => {
    return db.collection(LIVE_COLL)
}

export const getStreamCollRef = (eventId) => {
    return db.collection(LIVE_COLL)
        .doc(eventId)
        .collection('streams')
}

