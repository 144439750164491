import {lazy} from 'react';

export const CategoryNew = lazy(() => import('../components/Category/CategoryNew'))
export const MenuAppBar = lazy(() => import('../components/MenuAppBar/MenuAppBar'))
export const HomePage = lazy(() => import('../components/HomePage/HomePage'))
export const LiveStreaming = lazy(() => import('../components/LiveStreaming'))
export const AndomEvent = lazy(() => import('../components/AndomEvent/index'))
export const MetamorItems = lazy(() => import('../components/metamors/MetamorItems'))
export const Cart = lazy(() => import('../components/Cart'))
export const DialogBuyCoins = lazy(() => import('../components/BuyCoins/DialogBuyCoins'))
export const PopupPayment = lazy(() => import('../components/DialogPayment/PopupPayment'))
export const ProgramPage = lazy(() => import('../components/ProgramPage/ProgramPage'))
export const HistoryPage = lazy(() => import('../components/HistoryPage/HistoryPage'))
export const PurchasedEventHome = lazy(() => import('../components/PurchasedEventPage/PurchasedEventHome'))
