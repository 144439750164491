import axios from 'axios';
import {setGlobal} from 'reactn';
import {API_GENIAM, GET_USER_INFO} from '../../config/apiUrl';
import Cookies from 'js-cookie';
import {removeCookie, setCookie} from '../../common/cookies';
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN, IS_PRODUCTION} from '../../config/constant';
import {db} from '../../firebaseConfig';
import store from 'store';
import {USER_INFO_KEY} from '../../config/apis/constants';

export const getUserData = async () => {
    setGlobal({loading: true});
    const token = store.get(ACCESS_TOKEN_KEY);
    if (!token) {
        const res = await fetchRefreshToken();
        if (!res) {
            setGlobal({loading: false});
            return null;
        }
    }

    try {
        const {data} = await axios.get(GET_USER_INFO);
        const USER_COLL = IS_PRODUCTION ?
            'calendar' :
            'calendar-stg';
        const snap = await db.collection(USER_COLL).doc(data.user_id).get();
        if (snap.exists) {
            data.geniamUser = snap.data();
        }
        setGlobal({user: data});
        store.set(USER_INFO_KEY, data);
        return true;
    } catch (e) {
        setGlobal({user: {}});
        console.log(e);
        return null;
    } finally {
        setGlobal({loading: false});
    }

};

// in calendar removeURLParameter
// export const checkUrlToken = async () => {
//   let result = true
//   const accessToken = getCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
//   const refreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
//   if (accessToken || refreshToken) {
//     let uri = window.location.href;
//     uri = removeURLParameter("token", uri);
//     uri = removeURLParameter("refreshToken", uri);
//     window.history.pushState({}, document.title, uri);
//   } else {
//     result = false
//   }
//   return result
// }

export const fetchRefreshToken = async () => {
    const refreshToken = Cookies.get(GENIAM_REFRESH_TOKEN);
    // console.log(refreshToken);
    // if (!refreshToken) {
    //   window.location.assign(LOGIN_URI + '?redirect_url=' + window.location.href + '?cookies')
    // }
    //Neu can dang nhap sau khi refreshtoken thi hien thi o day
    try {
        const res = await axios.post(API_GENIAM + '/refresh', {refreshToken});
        const data = res.data;
        store.set(ACCESS_TOKEN_KEY, data.accessToken);
        if (data.refreshToken)
            setCookie(GENIAM_REFRESH_TOKEN, data.refreshToken);
        return data;
    } catch (e) {
        console.log(e);
        removeCookie(GENIAM_REFRESH_TOKEN);
        removeCookie(ACCESS_TOKEN_KEY);
        // window.location.assign(LOGIN_URI + '?redirect_url=' + window.location.href + '?cookies')
        return null;
    }
};

