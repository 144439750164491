import {useSnapshot} from 'valtio';
import {useEffect} from 'react';
import {concat, findIndex, groupBy, remove} from 'lodash';
import {useGlobal} from 'reactn';
import {eventsProxy} from '../valtio/proxy/events.proxy';
import {getGeniamEvents} from '../common/getGeniamEvents';
import {getStartEnd} from '../common/cordemia/extended';
import {getGeniamEventsFromReccrence} from '../common/getGoogleEvents';
import store from 'store';
import {useCalendarUser} from '../common/user';
import {snapshot} from 'valtio/vanilla';
import {projectsProxy} from '../valtio/proxy/projects.proxy';
import {getIdentityColor} from './common/getIdentityColor';
import {getProjectColor} from './common/getProjectColor';


export const useGeniamEvents = () => {
    const [user] = useGlobal("user")
    const {calendarUser} = useCalendarUser()
    const [dateList] = useGlobal('dateList');
    const {geniamProjects} = snapshot(projectsProxy)
    const {setGeEvents} = useSnapshot(eventsProxy)


    useEffect(() => {
        if (!user?.user_id)
            return;

        const sub = getGeniamEvents((snapshot) => {
            const {end} = getStartEnd(dateList)
            let data = snapshot.docs.map(doc => {
                const _event = {...doc.data(), id: doc.id,}

                if (_event?.colorId || _event?.backgroundColor) {
                    return {
                        ..._event,
                        backgroundColor: _event?.backgroundColor || getIdentityColor(_event.colorId),
                        borderColor: _event?.borderColor || getProjectColor(_event.project_uuid)
                    }
                } else {
                    return {
                        ..._event,
                        backgroundColor: getProjectColor(_event.project_uuid),
                        borderColor: getProjectColor(_event.project_uuid)
                    }
                }
            })

            data = groupBy(data, "project_uuid")
            let result = {}
            Object.keys(data).forEach(key => {
                let value = data[key]
                if (findIndex(geniamProjects, {uuid: key}) === -1)
                    return delete data[key]
                const recurrence = remove(value, item => item.recurrence)
                const recurrenceEdited = remove(value, e => e.recurringEventId)
                const recurring = getGeniamEventsFromReccrence(recurrence, recurrenceEdited, end)

                result[key] = {
                    events: concat(value, recurring),
                    id: key,
                }
            })
            store.set('geEvents', result)
            setGeEvents(result)
        })

        return () => {
            if (sub)
                sub()
        }
        // eslint-disable-next-line
    }, [geniamProjects, calendarUser, dateList])
}
