import {useEffect} from 'react';
import {getGlobal, setGlobal, useGlobal} from "reactn";
import {concat, findIndex, get, remove, uniqBy} from "lodash";
import {RRule, rrulestr} from 'rrule'
import moment from "moment";
import store from 'store'
import {removeStorageGoogleUser} from './googleCal/logOutGoogle';
import {formatGeniamEvents} from './getGeniamEvents';
import {ggTokenChecked} from '../config/auth';

export const getGoogleEvents = async (start, end, googleCalendars) => {
    if (!start || !end)
        return {}
    const {googleStatus, googleCalendarInit} = getGlobal();
    let results = {}
    let nextSyncToken = {}
    if (googleStatus?.is_login && googleCalendarInit) {
        const checked = await ggTokenChecked()
        if (!checked || !googleCalendars.length)
            return results

        await Promise.all(googleCalendars.map(async item => {
            try {
                const request = window.gapi.client.calendar.events.list({
                    'calendarId': item.id,
                    // "singleEvents": true,
                    "timeMin": start,
                    "timeMax": end,
                    "maxResults": 2500,
                    // "alwaysIncludeEmail":true
                })
                const {result, status} = await request.then()
                if (status !== 200)
                    return
                const calendarItems = result.items

                results[item.id] = {
                    id: item.id,
                    events: calendarItems || [],
                    nextSyncToken: calendarItems?.length ? (result?.nextSyncToken || null) : null,
                    name: item.summary,
                    color: item.backgroundColor
                }
                nextSyncToken[item.id] = result?.nextSyncToken || null
            } catch (e) {
                console.log(e);
            }
        }))

    }
    store.set("ggEvents", results)
    return {events: results, nextSyncToken}
}

// export const getGoogleEventsAllTime = async (title, start, end) => {
//     // console.log("get event by context")
//     const {googleStatus, googleCalendars, googleCalendarInit} = getGlobal()
//
//     let result = {}
//     let nextSyncToken = {}
//     if (googleStatus.is_login && googleCalendarInit) {
//         const checked = await ggTokenChecked()
//         if (!checked || !googleCalendars.length)
//             return result
//         const batch = window.gapi.client.newBatch()
//         googleCalendars.forEach(group => {
//             const request = window.gapi.client.calendar.events.list({
//                 'calendarId': group.id,
//                 "q": title,
//                 "maxResults": 2500,
//                 ...(start && {timeMin: moment(start).utc().format()}),
//                 ...(end && {timeMax: moment(end).utc().format()}),
//             })
//             batch.add(request)
//
//         })
//         const data = await batch.then()
//         if (data.result) {
//             if (data.status !== 200) {
//                 return null
//             }
//             let forbidden = false
//             Object.keys(data.result).forEach((item) => {
//                 try {
//                     if (data.result[`${item}`]?.status === 403) {
//                         // limit  request user/100s
//                         forbidden = true
//                     }
//                     let calendarItems = data.result[`${item}`]?.result?.items || []
//                     const recurrenceEvents = remove(calendarItems, e => e.recurrence)
//                     const recurenceEdited = remove(calendarItems, e => e.recurringEventId)
//                     const recurring = getEventsFromRecurrence(recurrenceEvents, recurenceEdited)
//                     if (calendarItems && calendarItems.length !== 0) {
//                         if (!calendarItems[0]?.organizer?.email)
//                             return
//                         let index = findIndex(googleCalendars, {id: calendarItems[0].organizer.email})
//                         if (index !== -1) {
//                             let isHoliday = data.result[`${item}`].result.accessRole === 'reader'
//                             let formatedItems = GCalendarEventFormat(concat(calendarItems, recurring), googleCalendars[index].id, googleCalendars[index].backgroundColor, isHoliday)
//                             result[calendarItems[0].organizer.email] = {
//                                 id: calendarItems[0].organizer.email,
//                                 events: formatedItems,
//                                 nextSyncToken: data.result[`${item}`].result?.nextSyncToken || null,
//                                 // nextEvents: filter(formatedItems, s => moment().isBefore(s.start)),
//                                 name: googleCalendars[index].summary,
//                                 color: googleCalendars[index].backgroundColor
//                             }
//                             nextSyncToken[calendarItems[0].organizer.email] = data.result[`${item}`].result?.nextSyncToken || null
//                         }
//                     }
//                 } catch (e) {
//                     console.log(e);
//                 }
//             })
//             if (forbidden) {
//                 return null
//             }
//         }
//
//     }
//     store.set("ggEventsAllTime", result)
//     return {events: result, nextSyncToken}
// }

export const getEventsFromRecurrence = (events, edited, endRange) => {
    const result = events.map(e => {
        try {
            if (e.recurrence && Array.isArray(e.recurrence)) {
                let idx = findIndex(e.recurrence, r => r.includes("RRULE:"))
                if (idx === -1)
                    return []
                const allDay = Boolean(e.start.date)
                const start = e.start.date || e.start.dateTime
                const end = e.end.date || e.end.dateTime
                const duration = moment(end).diff(start, allDay ? "day" : "minutes")
                const dtstart = `DTSTART:${moment(start).startOf("day").format("YYYYMMDDTHHmmss") + "Z"} `
                const eventDate = moment(start).format('YYYY-MM-DD')
                const rule = rrulestr(dtstart + e.recurrence[idx])
                let dataDate
                let ignore = true
                if (rule?.options?.until && moment(rule.options.until).isBefore(moment(start).startOf("day"))) {
                    dataDate = [moment(start).format('YYYY-MM-DD')]
                    ignore = false
                } else {
                    dataDate = rule.between(new Date(moment(start).utc().subtract(1, "day").format("YYYY-MM-DD")), new Date(moment(endRange).format("YYYY-MM-DD"))).map(date => moment(date).format("YYYY-MM-DD"))
                }
                if (!dataDate?.includes(eventDate)) {
                    dataDate.unshift(eventDate)
                }
                const eventData = dataDate.map((date, index) => {
                    if (allDay) {
                        return {
                            ...e,
                            start: {
                                date: date,
                                dateTime: null
                            },
                            end: {
                                date: moment(date).add(duration, "day").format("YYYY-MM-DD"),
                                dateTime: null
                            },
                            id: e.id + "_" + moment(date).format("YYYYMMDD"),
                            recurringEventId: e.id,
                            originStart: {
                                allDay: allDay,
                                start,
                                end,
                                isStart: index === 0
                            }
                        }
                    }
                    const startTime = moment(start).set({
                        years: moment(date).year(),
                        month: moment(date).month(),
                        date: moment(date).date()
                    }).format()
                    if (rule?.options?.until && ignore && moment(rule.options.until).isBefore(startTime)) {
                        return null
                    }
                    return {
                        ...e,
                        start: {
                            dateTime: startTime,
                            date: null
                        },
                        end: {
                            dateTime: moment(startTime).add(duration, "minutes").format(),
                            date: null
                        },
                        id: e.id + "_" + moment(date).set({
                            hours: moment(start).hours(),
                            minutes: moment(start).minutes(),
                            seconds: 0
                        }).utc().format("YYYYMMDDTHHmmss") + "Z",
                        recurringEventId: e.id,
                        originStart: {
                            allDay: allDay,
                            start: start,
                            end,
                            isStart: index === 0
                        },
                        editable: !e.isEventVote,
                        droppable: !e.isEventVote
                    }
                })
                edited.forEach(evt => {
                    let idx = findIndex(eventData, {id: evt.id})
                    if (idx !== -1) {
                        if (evt.status === "canceled") {
                            eventData.splice(idx, 1)
                        } else {
                            eventData[idx] = {
                                ...evt,
                                recurrence: e.recurrence,
                                originStart: eventData[idx].originStart
                            }
                        }
                    }
                })
                // console.log({eventData});
                return eventData.filter(x => x)
            } else {
                return []
            }
        } catch (error) {
            console.log(error, e);
            return []
        }

    })
    return result.flat()
}

export const getGeniamEventsFromReccrence = (events, edited = [], endRange) => {
    const result = events.map(e => {
        try {
            if (e.recurrence) {
                const allDay = Boolean(e.allDay)
                const start = e.start
                const end = e.end
                const eventDate = moment(start).format('YYYY-MM-DD')
                const endRecurrence = moment(e.start).add(1, "year").format()
                const duration = moment(end).diff(start, allDay ? "day" : "hour", true)
                const options = RRule.parseString(e.recurrence)
                options.dtstart = new Date(moment(e.start).local().startOf("day").format("YYYY-MM-DD"))
                const rule = new RRule({
                    ...options,
                    // tzid: 'Asia/Tokyo'
                })
                let dataDate
                let ignore = true
                if (rule?.options?.until && moment(rule.options.until).isBefore(moment(start).startOf("day"))) {
                    dataDate = [moment(start).format('YYYY-MM-DD')]
                    ignore = false
                } else {
                    dataDate = rule.between(new Date(moment(start).utc().subtract(1, "day").format("YYYY-MM-DD")), new Date(moment(endRecurrence).format("YYYY-MM-DD"))).map(date => moment(date).format("YYYY-MM-DD"))
                }
                if (!dataDate?.includes(eventDate)) {
                    dataDate.unshift(eventDate)
                }
                const eventData = dataDate.map((date, index) => {
                    if (allDay) {
                        return {
                            ...e,
                            start: date,
                            end: moment(date).add(duration, "day").format("YYYY-MM-DD"),
                            id: e.id + "_" + moment(date).format("YYYYMMDD"),
                            recurringEventId: e.id,
                            originStart: {
                                allDay: allDay,
                                start,
                                end,
                                isStart: index === 0
                            }
                        }
                    }
                    const startTime = moment(start).set({
                        years: moment(date).year(),
                        month: moment(date).month(),
                        date: moment(date).date()
                    }).format()
                    if (rule?.options?.until && ignore && moment(rule.options.until).isBefore(startTime)) {
                        return null
                    }
                    return {
                        ...e,
                        start: startTime,
                        end: moment(startTime).add(duration, "hours").format(),
                        id: e.id + "_" + moment(startTime).utc().format("YYYYMMDDTHHmmss") + "Z",
                        recurringEventId: e.id,
                        originStart: {
                            allDay: allDay,
                            start,
                            end,
                            isStart: index === 0
                        },
                        editable: !e.isEventVote,
                        droppable: !e.isEventVote
                    }
                })
                edited.forEach(evt => {
                    let idx = findIndex(eventData, {id: evt.id})

                    if (idx !== -1) {
                        if (evt.status === "canceled" || evt.status === 'cancelled') {
                            eventData.splice(idx, 1)
                        } else {
                            eventData[idx] = {
                                ...evt,
                                recurrence: e.recurrence,
                                originStart: eventData[idx].originStart
                            }
                        }
                    }
                })
                return eventData.filter(x => x)
            } else {
                return []
            }
        } catch (e) {
            return []
        }
    })
    return result.flat()
}

export const GCalendarEventFormat = (events, group_uuid, color, holiday = false) => {
    let result = []
    events.forEach(e => {
        let event = eventGoogleFormat(e, group_uuid, color, holiday)
        if (event)
            result.push(event)
    })
    return result
};

export const eventGoogleFormat = (e, group_uuid, color, holiday = false) => {
    const {GoogleColor} = getGlobal()
    if (e.start && e.end) {
        let allDay = !!e.start.date
        let start = e.start.dateTime || e.start.date
        let end = e.end.dateTime || e.end.date
        let resourceId = allDay ? group_uuid : null
        let title = removeEmoji(e.summary, e?.extendedProperties?.private?.task || null).trim(" ")
        let isEventVote = e?.extendedProperties?.private?.isEventVote === 'true' || Boolean(e?.extendedProperties?.private?.meetingId) || Boolean(e?.extendedProperties?.private?.shareUrl)
        let event = {
            ...e,
            id: e.id,
            uuid: e.id,
            start,
            end,
            title: title || (holiday ? "busy" : '(no title)'),
            colorId: e?.colorId ? Number(e.colorId) : null,
            backgroundColor: e?.colorId ? (GoogleColor[e.colorId - 1] || color) : color,
            borderColor: color,
            allDay,
            resourceId,
            duration: (moment(end) - moment(start)) / (60000 * 60), // minutes
            calendar_uuid: group_uuid,
            project_uuid: group_uuid,
            googleEvent: true,
            editable: !holiday,
            draggable: !holiday,
            holiday: holiday,
            task: e?.extendedProperties && e.extendedProperties?.private?.task ? e.extendedProperties.private.task : null,
            isSuccess: e?.extendedProperties && e?.extendedProperties?.private?.is_success ? Boolean(parseInt(e.extendedProperties.private.is_success)) : false,
            pro_uuid: e?.extendedProperties?.private?.project_uuid || null,
            isMileStone: Boolean(e?.extendedProperties?.private?.task === "mileStone"),
            isEventVote: isEventVote,
            linkZoom: e?.extendedProperties && e?.extendedProperties?.private?.linkZoom ? e.extendedProperties.private.linkZoom : null,
            zoomMeetingId: e?.extendedProperties && e?.extendedProperties?.private?.zoomMeetingId ? e.extendedProperties.private.zoomMeetingId : null,
            meetingId: e?.extendedProperties?.private?.meetingId || null,
            scheduleId: get(e, 'extendedProperties.private.scheduleId', null),
            isMilestoneHide: Boolean(e?.extendedProperties?.private?.isMilestoneHide === "true"),
            isMilestoneNextMonth: Boolean(e?.extendedProperties?.private?.isMilestoneNextMonth === "true"),
            conferenceData: e?.conferenceData || null,
            googleTasksID: e?.extendedProperties?.private?.googleTasksID || null,
            email: e?.extendedProperties?.private?.email || '',
            emailVoter: e?.extendedProperties?.private?.emailVoter || '',
            name: e?.extendedProperties?.private?.name || '',
        }
        if (e.recurrence && Array.isArray(e.recurrence)) {
            event.recurrence = e.recurrence[0]
        }
        if (event.isEventVote && event.meetingId) {
            event.isAccepted = true
        }
        return event
    }
    return null
}

const removeEmoji = (title, task) => {
    const {emojis} = getGlobal()
    let result = title || ""
    if (task && title) {
        let index = findIndex(emojis, {'name': task})
        if (index !== -1) {
            result = title.split(emojis[index].emoji)[0]
        }
    }
    return result
}

export const useGGStatus = () => {
    // check user is sync with
    const [status, setGGStatus] = useGlobal('googleStatus')
    const [calendarUser] = useGlobal('calendarUser')

    useEffect(() => {
        if (!calendarUser?.googleAuth)
            return;

        if (calendarUser?.googleAuth) {
            store.set("googleAuth", calendarUser.googleAuth)
            let newStatus = {...status}
            if ((!newStatus.is_login || newStatus.userProfile?.email !== calendarUser.googleAuth.email)) {
                newStatus.is_login = true
                newStatus.userProfile = calendarUser.googleAuth
                setGGStatus({...newStatus})
            }
        } else {
            setGlobal({
                googleStatus: {
                    is_login: false,
                    userProfile: {},
                    initialed: true
                },
                googleCalendars: [],
                ggEventLoaded: true
            }, () => {
                // refetchSource('google')
            })
            removeStorageGoogleUser()
        }
        // eslint-disable-next-line
    }, [calendarUser])
}

export const getGoogleSelectedEvents = (events = {}, start, end) => {
    const {googleCalendars, user} = getGlobal()
    const hiddenList = user?.idCalenderHidden || []
    let result = []

    googleCalendars.forEach(pro => {
        if (pro.selected && !hiddenList.includes(pro.id)) {
            let totalEvents = [...(events[pro.id]?.events || [])]
            const recurrenceEvents = remove(totalEvents, e => e.recurrence)
            const recurenceEdited = remove(totalEvents, e => e.recurringEventId)
            const endRange = moment(end).endOf("days").add(1, "day").utc().format()
            const recurring = getEventsFromRecurrence(recurrenceEvents, recurenceEdited, endRange)
            const isHoliday = pro.accessRole === "reader" || pro.accessRole === "freeBusyReader"
            const formatItems = GCalendarEventFormat(concat(totalEvents, recurring), pro.id, pro.backgroundColor, isHoliday)
            result = concat(result, getEventsInRange(formatItems, start, end))
        }

    })
    return uniqBy(result, "id")
}

export const getEventsInRange = (events, start, end) => {
    let result = []
    events.forEach(event => {
        if (moment(event.start).isBetween(start, end, null, "[]") ||
            moment(event.end).isBetween(start, end, null, "[]") ||
            (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))) {

            // truong hop event meeting sync khong co color
            if (event?.meetingId && event?.project_uuid && !event?.borderColor) {
                event = formatGeniamEvents(event)
            }
            result.push(event)
        }
    })
    return result
}
