import {useDocumentData} from 'react-firebase-hooks/firestore';
import {getCoinPath} from '../common/getPathFirestore';
import {useEffect} from 'react';
import {useSnapshot} from 'valtio';
import {coinsProxy} from '../valtio/proxy/coins.proxy';

export const useCoins = () => {
    const [coins, loading] = useDocumentData(getCoinPath())
    const {setCoins} = useSnapshot(coinsProxy)

    useEffect(() => {
        const onSetCoins = () => {
            if (loading || (!coins && !loading))
                return;
            setCoins(coins)
        }

        onSetCoins()

        // eslint-disable-next-line
    }, [coins, loading])

}
