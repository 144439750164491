import {db} from '../../firebaseConfig';
import {COINS_PACK_COLL} from '../../config/constant';

const getCoinPath = () => {
    return db.doc(`${COINS_PACK_COLL}/cordemiaCoins`)
}

export {
    getCoinPath
}
