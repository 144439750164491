import axios from 'axios';
import {API_GENIAM} from '../config/apiUrl';
import {uniq} from 'lodash'
import {products} from './products';
import {setGlobal} from 'reactn'

const getProductsPlan = (prices = []) => {
    let plans = []
    products.forEach(item => {
        if (prices.includes(item.price.month) || prices.includes(item.price.year)) {
            plans = item.used
        }
    })
    return plans
}


export const getStripeSubs = async () => {
    try {
        const {data} = await axios.get(`${API_GENIAM}/subscriptions/list`)
        const prices = uniq(data.data.map(item => item?.plan?.id || '').filter(x => x))

        const listProducts = getProductsPlan(prices)
        setGlobal({
            hxProducts: listProducts
        })
    } catch (e) {
        return null
    }
}
