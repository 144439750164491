import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import isLocalhost from './common/isLocalhost';

import {
    FIRESTORE_EMULATOR_HOST,
    FIRESTORE_EMULATOR_PORT,
    IS_PRODUCTION,
    STORAGE_EMULATOR_HOST,
    STORAGE_EMULATOR_PORT,
    USE_EMULATOR
} from './config/constant';

const firebaseConfig = IS_PRODUCTION ? {
    apiKey: 'AIzaSyBROAxpDRqRIqZvrAGE7UK7uvaIUcxooS0',
    authDomain: 'geniam-c8d4c.firebaseapp.com',
    databaseURL: 'https://geniam-c8d4c.firebaseio.com',
    projectId: 'geniam-c8d4c',
    storageBucket: 'geniam-c8d4c.appspot.com',
    messagingSenderId: '92022981782',
    appId: '1:92022981782:web:17a2ab710f867e60b56eb7',
    measurementId: 'G-ZZVDYP9EN4',
} : {
    apiKey: "AIzaSyD84J67YueP6HvVXbJtime2VaKnc3M1ZlE",
    authDomain: "geniam-dev.firebaseapp.com",
    projectId: "geniam-dev",
    storageBucket: "geniam-dev.appspot.com",
    messagingSenderId: "469887895881",
    appId: "1:469887895881:web:507ee859696cf3f9b5b298",
    measurementId: "G-8VDE2ZHNW7"
};

if (!firebase.apps.length)
    firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

if (USE_EMULATOR && isLocalhost()) {
    db.useEmulator(FIRESTORE_EMULATOR_HOST, FIRESTORE_EMULATOR_PORT)
    storage.useEmulator(STORAGE_EMULATOR_HOST, STORAGE_EMULATOR_PORT)
    console.warn(`USING EMULATORS`)
}

const Fire = firebase;
export default Fire;
if (typeof window !== 'undefined') window.Fire = Fire;

