import {setGlobal} from 'reactn';

export default function GoogleAuth() {

    const src = 'https://apis.google.com/js/platform.js';
    let script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);
    document.body.appendChild(script);

}

function onScriptError() {
    console.log('error !!!');
}

function onScriptLoad() {
    // window.gapi.load('client:auth2', function () {
    //     window.gapi.client.load('calendar', 'v3', function () {
    //         setGlobal({
    //             ggCalInit: true,
    //         });
    //     });
    // });
    window.gapi.load('client:auth2', function () {
        window.gapi.client.load('calendar', 'v3', function () {
            const params = {
                client_id: "92022981782-qpp7h79g7a3d166gqhlsm12q7r0gehbq.apps.googleusercontent.com",
                scope: 'profile  email https://www.googleapis.com/auth/calendar',
                fetch_basic_profile: true,
                prompt: 'select_account',
                access_type: "offline",
                response_type: "code",
            }
            window.gapi.auth2.init(params).then(GoogleAuth => {
                setGlobal({
                    googleCalendarInit: true
                })
                //
            }).catch(e => {
                console.log(e)
                setGlobal({
                    googleStatus: {
                        is_login: false,
                        userProfile: {},
                        initialed: true
                    },
                    googleCalendarInit: e.details === "Cookies are not enabled in current environment."
                })
            })
        })

    })
}


