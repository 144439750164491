import {setGlobal} from 'reactn';
import store from 'store';
import {USER_INFO_KEY} from './config/apis/constants';
import qs from "query-string";
import {contentList} from './common/cordemia/cordemiaContents';
import {mmFormat} from 'use-moment/format';


const query = qs.parse(window.location.search)
const tabShareUrl = query?.tabShareUrl || store.get('tabShareUrl')

setGlobal({
    calIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => 'Cal_' + i),
    numOfCals: 1,
    date: new Date(),
    contents: [],
    schedules: [],
    dateList: [],
    showCart: false,
    isJoinRedirect: false,
    isUnsubscribe: false,
    subscriptions: [],
    // listSelectedEvents: [],
    metamor: [],
    tickets: 0,
    categories: [],
    user: store.get(USER_INFO_KEY) || {},
    myTickets: 0,
    anchorEl: null,
    loading: true,
    rankPrices: [],
    myPaid: [],
    history: [],
    hover: false,
    metamorHoverAnchor: null,
    hoverMetamor: {},
    hoverListRoom: false,
    totalOfTickets: 0,
    filterSchedules: [],
    tasks: {
        data: [],
        default: 0,
    },
    itemPopMiddle: {},
    colors: {primary: '#1790FF', secondary: '#690003'},
    openAddTask: null,
    showPay: false,
    packageCoins: null,
    geEvents: {},
    ggEvents: {},
    googleCalendars: store.get("GGList") || [],
    projects: [],
    GoogleColor: [
        "#7986CB",
        "#33B679",
        "#8E24AA",
        "#E67C73",
        "#F6BF26",
        "#F4511E",
        "#039BE5",
        "#616161",
        "#3F51B5",
        "#0B8043",
        "#D50000"
    ],
    calendarUser: {},
    googleStatus: {
        is_login: Boolean(store.get("googleAuth")),
        userProfile: store.get("googleAuth") || {},
        initialed: Boolean(store.get("googleAuth"))
    },
    hoverEvent: null,
    navigationsApps: [],
    searchKeyHistory: store.get('searchKeyHistory') || [],
    searchKey: "",
    eventSearchKey: "",
    meetingPrimary: true,
    listSettingShareUrl: [],
    tabShareUrl: tabShareUrl ? tabShareUrl : "live",
    cordemiaContents: store.get('contents') || contentList,
    hxProducts: [],
    allProject: [],
    groups: [],
    openCal: false,
    myUserData: {},
    clickInfo: null,
    refetchDateNow: mmFormat(new Date(), 'YYYY-MM-DD'),
    zoomOAuth: {},
    voteEvents: [],
    editInfo: null,
    copyCreateEvent: null,
    isDragging: false,
    hoverEl: null,
    hoverEventStart: null,
    prevAction: [],
    recurringEvent: {},
    recurringOpen: false,
    scheduleAdjustment: null,
    editAllDayEvent: null,
    userGeniamApps: [],
    globalApps: [],
    userLinks: [],
    showDetail: false,
    eventLearn: null,
    idEventJoined: null,
    googleCalendarInit: false,
    dropInfo: {}
});
