import {useSnapshot} from 'valtio';
import {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import {projectsProxy} from '../valtio/proxy/projects.proxy';
import {eventsProxy} from '../valtio/proxy/events.proxy';
import {getStartEnd} from '../common/cordemia/extended';
import {getGoogleEvents} from '../common/getGoogleEvents';
import {isListChange} from '../common/cordemia/cordemiaContents';
import {useGlobal} from 'reactn';
import {snapshot} from 'valtio/vanilla';


export const useGoogleEvents = () => {
    const [googleCalendarInit] = useGlobal("googleCalendarInit")
    const [dateList] = useGlobal('dateList');
    const [editInfo] = useGlobal("editInfo")
    const [editAllDayEvent] = useGlobal('editAllDayEvent')
    const [, setNextSyncToken] = useGlobal("nextSyncTokens")
    const {googleProjects} = snapshot(projectsProxy)
    const {setGgEvents} = useSnapshot(eventsProxy)
    const [list, setList] = useState([])

    useEffect(() => {
        if (isListChange(list, googleProjects)) {
            setList([...googleProjects])
        }

        // eslint-disable-next-line
    }, [googleProjects])

    useEffect(() => {
        if (list?.length && googleCalendarInit) {
            const reLoadEvents = async () => {
                const {start, end} = getStartEnd(dateList)
                if (!list?.length) {
                    setGgEvents({})
                    return
                }
                try {
                    const {events, nextSyncToken} = await getGoogleEvents(start, end, list)
                    if (isEmpty(events)) {
                        return
                    }
                    await setNextSyncToken(nextSyncToken)
                    setGgEvents(events)
                } catch (e) {
                    console.log(e);
                }
            }

            reLoadEvents().then()
        }


        // eslint-disable-next-line
    }, [list, googleCalendarInit, editInfo, editAllDayEvent])

}
