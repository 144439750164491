import {getGlobal} from 'reactn'
import moment from "moment";


export const getDateRangeOfCal = (date, numOfCal) => {
    const type = getType()
    const start = moment(date).add(numOfCal, type).startOf(type).utc().format()
    const end = moment(start).add(numOfCal, type).endOf(type).utc().format()
    return {
        start,
        end
    }
}

const getType = () => {
    const {view} = getGlobal()
    let type
    switch (view) {
        case "CustomWeek":
            type = "week"
            break
        case "CustomDay":
            type = "day"
            break
        default:
            type = "month"
    }
    return type
}
