import {getGlobal, setGlobal} from 'reactn'
import store from "store";
import moment from "moment";
import axios from "axios";
import {onLogOut} from "../common/googleCal/logOutGoogle";
import {msgError} from '../common/msg';
import {db} from '../firebaseConfig';
import {CALENDAR_COLLECTION} from './constant';
import {API_GENIAM} from './apiUrl';

export default function GoogleAuth() {

    const src = "https://apis.google.com/js/platform.js"
    let script = document.createElement('script')
    script.src = src
    script.async = true
    script.addEventListener('load', onScriptLoad)
    script.addEventListener('error', onScriptError)
    document.body.appendChild(script)


}

function onScriptError() {
    console.log('error !!!')
}

function onScriptLoad() {
    window.gapi.load('client:auth2', function () {
        window.gapi.client.load('calendar', 'v3', function () {
            const params = {
                client_id: "92022981782-qpp7h79g7a3d166gqhlsm12q7r0gehbq.apps.googleusercontent.com",
                scope: 'profile  email https://www.googleapis.com/auth/calendar',
                fetch_basic_profile: true,
                prompt: 'select_account',
                access_type: "offline",
                response_type: "code",
            }
            window.gapi.auth2.init(params).then(GoogleAuth => {
                setGlobal({
                    googleCalendarInit: true
                })
                //
            }).catch(e => {
                console.log(e)
                setGlobal({
                    googleStatus: {
                        is_login: false,
                        userProfile: {},
                        initialed: true
                    },
                    googleCalendarInit: true
                })
            })
        })

    })
    // window.gapi.load('client')

}

export const ggTokenChecked = async () => {
    const {googleStatus, user, googleCalendarInit} = getGlobal()
    try {
        if (!googleStatus.is_login || !googleCalendarInit)
            return null;

        let ggToken = store.get('ggToken');

        if (!ggToken || !ggToken.exp || moment().add(10, "minutes").isAfter(ggToken?.exp) || googleStatus.userProfile?.email !== ggToken?.email) {
            const tokenRespone = await axios.get(`${API_GENIAM}/googleApi/refreshToken?refreshToken=${googleStatus.userProfile.refreshToken}`)
            if (!tokenRespone.data.scope?.includes("https://www.googleapis.com/auth/calendar")) {
                if (user?.user_id) {
                    // remove refresh token
                    const ref = db.collection(CALENDAR_COLLECTION).doc(user.user_id)
                    await ref.set({googleAuth: null}, {merge: true})
                }
                onLogOut()
                return;
            }
            ggToken = {
                token: tokenRespone.data.access_token,
                exp: moment().add(1, "hours").utc().format(),
                email: googleStatus.userProfile.email
            }
            store.set('ggToken', ggToken)
        }
        window.gapi.auth.setToken({
            "access_token": ggToken.token
        })
        return ggToken
    } catch (e) {
        console.log(e);
        msgError("Token has been expired or revoked. login google to sync data")
        setGlobal({
            googleStatus: {
                is_login: false,
                userProfile: {},
                initialed: true
            },
            googleCalendars: []
        })
        // delete exists token expired or revoked
        removeToken(user?.user_id)
        return null
    }

}
const removeToken = userId => {
    if (!userId)
        return null
    const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
    userRef.set({
        googleAuth: null
    }, {merge: true})
        .catch(console.log)
}

