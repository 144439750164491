import {proxy} from 'valtio/vanilla';

const coinsProxy = proxy({
    coins: {},
    setCoins: (coin) => {
        coinsProxy.coins = coin
    }
})

export {
    coinsProxy
}
