import {useSnapshot} from 'valtio';
import {useEffect} from 'react';
import remove from 'lodash/remove'
import {projectsProxy} from '../valtio/proxy/projects.proxy';
import {useCollection} from 'react-firebase-hooks/firestore';
import {myPrjGECollRef} from '../common/firebaseRef/useRef';


export const useMyGEProjects = () => {
    const [list] = useCollection(myPrjGECollRef().where("is_deleted", "==", false))
    const {setGeniamProjects} = useSnapshot(projectsProxy)


    useEffect(() => {
        if (list) {
            let data = list.docs.map(pro => ({
                ...pro.data(),
                id: pro.id,
                msSelected: pro.data().msSelected !== undefined ? pro.data().msSelected : true,
                apSelected: pro.data().apSelected !== undefined ? pro.data().apSelected : true,
            }));
            remove(data, d => d?.is_sync || d?.is_deleted)
            setGeniamProjects(data)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list])

}
