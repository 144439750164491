import {ggTokenChecked} from '../../config/auth';
import {getGlobal} from 'reactn';
import {getDateRangeOfCal} from '../../context/common/getSelectedEvents';
import moment from 'moment';

export const refetchSource = async (name) => {
    // const {projectSortSetting, allMilestoneProject} = getGlobal()
    // let calendar = getNumOfCals()
    // if (window.location.pathname === '/mileStone' && projectSortSetting.isSort) {
    //     calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)
    // }

    if (name === "google") {
        await ggTokenChecked()
    }
    // for (let i = 0; i < calendar; i++) {
    //     if (window['calendar' + i] && window['calendar' + i].view) {
    //         let source = window['calendar' + i].getEventSourceById(name)
    //     }
    // }
}

export const removeEvent = (id, numOfCal) => {
    if (!id)
        return

    if (window['calendar' + numOfCal] && window['calendar' + numOfCal].view) {
        const event = window['calendar' + numOfCal].getEventById(id)
        if (event)
            event.remove()
    }
}

const getNumOfCals = () => {
    const {calendarUser} = getGlobal()
    let numOfCal = 2
    if (calendarUser?.themeCustom) {
        const {rows, columns} = calendarUser?.themeCustom
        if (rows && columns) {
            numOfCal = rows * columns
        }
    }
    return numOfCal
}

export const addEventSource = (event) => {
    const sourceName = (event.is_google || event.googleEvent) ? 'google' : ((event.isEventVote && !event.project_uuid) ? "vote" : 'geniam')
    const {refetchDateNow} = getGlobal()
    let numOfCal = getNumOfCals()

    for (let i = 0; i < numOfCal; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById(sourceName)
            let {
                start,
                end
            } = window['calendar' + i]?.currentData?.dateProfile?.currentRange || getDateRangeOfCal(refetchDateNow, i)
            start = moment(start).startOf("day").format()
            end = moment(end).endOf("day").format()
            if (moment(event.start).isBetween(start, end, null, "[]") ||
                moment(event.end).isBetween(start, end, null, "[]") ||
                (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))
            ) {
                window['calendar' + i].addEvent(event, source)
            }
        }
    }
}


export const refetchAll = async (cal) => {
    await ggTokenChecked()
    if (window['calendar' + cal] && window['calendar' + cal].view) {
        window['calendar' + cal].refetchEvents()
    }
}

export const refetchOne = (i) => {
    if (window['calendar' + i] && window['calendar' + i].view) {
        window['calendar' + i].refetchEvents()
    }
}

export const refetchOneSource = (name, i) => {
    if (name && window['calendar' + i] && window['calendar' + i].view) {
        let source = window['calendar' + i].getEventSourceById(name)
        if (source)
            source.refetch()
    }
}

export const addOneCalendarEventSource = (event, numberId) => {
    const sourceName = (event.is_google || event.googleEvent) ? 'google' : ((event.isEventVote && !event.project_uuid) ? "vote" : 'geniam')
    const calendar = window['calendar' + numberId]
    if (calendar && calendar.view) {
        let source = calendar.getEventSourceById(sourceName)
        calendar.addEvent(event, source)
    }
}


