import React, {Suspense, useEffect} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import './App.css';
import 'tippy.js/dist/tippy.css'; // optional
import {Route, Switch, withRouter} from 'react-router-dom';
import qs from 'query-string';
import {getUserData} from './components/actions/user';
import Cookies from 'js-cookie';
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN, IS_PRODUCTION} from './config/constant';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import {auth} from './firebaseConfig';
import {signInFirebase} from './common/user';
import ClfLinearProgress from './components/Custom/CLFLinearProgress';
import {msgSuccess} from './common/msg';
import store from 'store';
import {getStripeSubs} from './common/getSubscriptions';
import {StreamsEventsProvider} from './context/StreamsEventsContext';
import {DatesProvider} from './context/DatesContext';
import {
    AndomEvent,
    Cart,
    DialogBuyCoins,
    HistoryPage,
    HomePage,
    LiveStreaming,
    MenuAppBar,
    MetamorItems,
    PopupPayment,
    ProgramPage,
    PurchasedEventHome
} from './components/spliting';
import {useUserData} from './hooks/useUser';
import {useGGStatus} from './common/getGoogleEvents';

function App({history}) {
    const [loading] = useGlobal('loading');
    const path = window.location.pathname;
    const query = qs.parse(window.location.search);

    useUserData()
    useGGStatus()

    useEffect(() => {
        document.title = !IS_PRODUCTION ? `[${process.env.REACT_APP_ENV}] ${document.title}` : document.title
    }, [])

    useEffect(() => {
        if (window.location.pathname === '/' || window.location.pathname === '/program' || window.location.pathname === '/purchasedEvent')
            return
        if (!window.location.href.includes('andom') || !window.location.href.includes('event'))
            window.location.assign(`/`)
        const splitPath = window.location.pathname.split('/')
        if (!splitPath[3])
            window.location.assign(`/`)
        if (splitPath[4] && (splitPath[4] !== 'stream' || !splitPath[5]))
            window.location.assign(`/`)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const {
            token,
            refreshToken,
            showCart,
            donePaid,
        } = qs.parse(window.location.search);
        if (donePaid) {
            msgSuccess(' Thanks To Purchase ');
        }
        if (showCart) {
            setGlobal({showCart: true});
        }
        if (token || refreshToken) {
            const opts = {expires: 1826};
            if (IS_PRODUCTION)
                opts.domain = '.geniam.com';
            store.set(ACCESS_TOKEN_KEY, token);
            Cookies.set(GENIAM_REFRESH_TOKEN, refreshToken, opts);
            store.set(GENIAM_REFRESH_TOKEN, refreshToken);

            delete query.token;
            delete query.refreshToken;
            if (_.isEmpty(query)) {
                // history.replace(`${path}`);
                window.history.replaceState(null, '', path)
            } else {
                history.push(`${path}?${qs.stringify(query)}`);
            }
        }

        getUserData().then(res => {
            getStripeSubs().then()
        }).catch(e => {
            console.log(e);
        });

        auth.onAuthStateChanged(user => {
            if (!user) {
                signInFirebase().then();
            }
        });

        // eslint-disable-next-line
    }, []);

    if (loading)
        return <ClfLinearProgress/>;

    return (
        <Switch>
            <Suspense fallback={<ClfLinearProgress/>}>
                {
                    window.location.pathname !== "/andom/event/hichori" && <MenuAppBar/>
                }
                <div className={`App ${window.location.pathname !== "/andom/event/hichori" ? 'marTop' : ''}`}>

                    <StreamsEventsProvider>
                        <DatesProvider>
                            <Route exact path={'/'} component={HomePage}/>
                            <Route exact path={'/program'} component={ProgramPage}/>
                            <Route exact path={'/history'} component={HistoryPage}/>
                            <Route exact path={'/purchasedEvent'} component={PurchasedEventHome}/>
                        </DatesProvider>
                        <Route exact path={'/andom/event/:id/stream/:streamId'} component={LiveStreaming}/>
                    </StreamsEventsProvider>

                    <Route exact path={'/mtview'} component={MetamorItems}/>
                    <Route exact path={'/andom/event/:id'} component={AndomEvent}/>

                    <DialogBuyCoins/>
                    <PopupPayment/>
                    <Cart/>
                    <ToastContainer
                        hideProgressBar={true}
                        autoClose={1500}
                    />
                </div>
            </Suspense>
        </Switch>
    );
}

export default withRouter(App);
