import {IS_PRODUCTION} from '../../config/constant';
import moment from 'moment';
import {findIndex} from 'lodash';

export const contents = [
    {
        id: "ctn_7Xijv9YjMJI6a",
        name: 'インサイトマップ',
        title: 'インサイトマップ',
        index: 1,
        image: '/image/cordemia/new/svd.webp',
        imageDefault: '/image/cordemia/new/svddefault.webp',
        x: 432,
        y: 338,
        width: 230
    },
    {
        id: "ctn_6qiPMp4OPBSJB",
        name: '<div>ユニゾン<br/>リスニング</div>',
        title: 'ユニゾンリスニング',
        index: 2,
        image: '/image/cordemia/new/khandai1.webp',
        imageDefault: '/image/cordemia/new/khandaidefault.webp',
        x: 585,
        y: 493,
        width: 147
    },
    {
        id: "ctn_6qiP060nmktxe",
        name: 'エネカラー',
        title: 'エネカラー',
        index: 4,
        image: '/image/cordemia/new/hopdem.webp',
        imageDefault: '/image/cordemia/new/hopdemdefault.webp',
        x: 717,
        y: 410,
        width: 125
    },
    {
        id: "ctn_6qiPMp4Rb4S4a",
        name: 'アイミング',
        title: 'アイミング',
        index: 6,
        image: '/image/cordemia/new/cau@2x.webp',
        imageDefault: '/image/cordemia/new/cau@2xdefault.webp',
        x: 838,
        y: 319,
        width: 166
    },
    {
        id: "ctn_4Xijv9ZjNJI5b",
        name: '&理論',
        title: '&理論',
        index: 7,
        image: '/image/cordemia/new/tttm.webp',
        imageDefault: '/image/cordemia/new/tttmdefault.webp',
        x: 256,
        y: 295,
        width: 151
    },
]

export const contentList = ['ctn_6qiP060nmktxe', 'ctn_6qiPMp4OPBSJB', 'ctn_6qiPMp4Rb4S4a', 'ctn_7Xijv9YjMJI6a', 'ctn_4Xijv9ZjNJI5b']

export const cordemiaOptions = IS_PRODUCTION ? [
        {
            name: 'HX エキスパート',
            label: 'HX エキスパート',
            product_id: "prod_Kp7BmTB7K1vSpO",
            value: "prod_Kp7BmTB7K1vSpO",
            id: "prod_Kp7BmTB7K1vSpO",
            isPackage: false,
            createdAt: moment().format(),
            includes: ['prod_Kp7BmTB7K1vSpO', 'prod_Kp7BMBp6j67aQC', 'prod_Kp7AHLTSapUz5E']
        },
        {
            name: 'HX スペシャリスト',
            label: 'HX スペシャリスト',
            product_id: "prod_Kp7BMBp6j67aQC",
            value: "prod_Kp7BMBp6j67aQC",
            id: "prod_Kp7BMBp6j67aQC",
            isPackage: false,
            createdAt: moment().format(),
            includes: ['prod_Kp7BMBp6j67aQC', 'prod_Kp7AHLTSapUz5E']
        },
        {
            name: 'HX プロフェッショナル',
            label: 'HX プロフェッショナル',
            product_id: 'prod_Kp7AHLTSapUz5E',
            value: "prod_Kp7AHLTSapUz5E",
            id: "prod_Kp7AHLTSapUz5E",
            isPackage: false,
            createdAt: moment().format(),
            includes: ['prod_Kp7AHLTSapUz5E']
        },
    ]
    :
    [
        {
            name: 'HX エキスパート',
            label: 'HX エキスパート',
            product_id: "prod_Kp1esAcpRs85yb",
            value: "prod_Kp1esAcpRs85yb",
            id: "prod_Kp1esAcpRs85yb",
            isPackage: false,
            createdAt: moment().format(),
            includes: ['prod_Kp1esAcpRs85yb', 'prod_Kp1doQSIKZVWsp', 'prod_Kp0lgoc1ypEZcs']
        },
        {
            name: 'HX スペシャリスト',
            label: 'HX スペシャリスト',
            product_id: "prod_Kp1doQSIKZVWsp",
            value: "prod_Kp1doQSIKZVWsp",
            id: "prod_Kp1doQSIKZVWsp",
            isPackage: false,
            createdAt: moment().format(),
            includes: ['prod_Kp1doQSIKZVWsp', 'prod_Kp0lgoc1ypEZcs']
        },
        {
            name: 'HX プロフェッショナル',
            label: 'HX プロフェッショナル',
            product_id: 'prod_Kp0lgoc1ypEZcs',
            value: "prod_Kp0lgoc1ypEZcs",
            id: "prod_Kp0lgoc1ypEZcs",
            isPackage: false,
            createdAt: moment().format(),
            includes: ['prod_Kp0lgoc1ypEZcs']
        },
    ]

export const cordemiaIds = IS_PRODUCTION ? ['prod_Kp7BmTB7K1vSpO', 'prod_Kp7BMBp6j67aQC', 'prod_Kp7AHLTSapUz5E'] : ['prod_Kp1esAcpRs85yb', 'prod_Kp1doQSIKZVWsp', 'prod_Kp0lgoc1ypEZcs']

export const products = IS_PRODUCTION ?
    [
        {
            name: "HXプロフェッショナル",
            subName1: "ツールを使ってHX支援",
            subName2: "Live配信と録画で",
            subName3: "１人でいつでも学べる",
            id: "prod_Kp7AHLTSapUz5E",
            price: {
                month: "price_1K9SwUKtz9s4YUGtqrjodzI9",
                year: "price_1K9SwUKtz9s4YUGti4AR1yYh"
            },
            amount: {
                month: 4980,
                year: 49800,
                coinsPlus: 10000,
                saved: 9960,
                totalSaved: 19960,
            },
            image: "/image/geniamplan2.svg",
            background: "linear-gradient(91.53deg, #9F2AFB -0.82%, #DF2D6D 99.49%)",
            color: "#9F2AFB"
        },
        {
            name: "HXスペシャリスト",
            subName1: "ツールを使ってHX支援",
            subName2: "1on1の実践と対話で",
            subName3: "仲間と切磋琢磨できる",
            id: "prod_Kp7BMBp6j67aQC",
            price: {
                month: "price_1K9SwhKtz9s4YUGtJM8ro5ug",
                year: "price_1K9SwgKtz9s4YUGtfulWSaFc"
            },
            amount: {
                month: 9980,
                year: 99800,
                coinsPlus: 30000,
                saved: 19960,
                totalSaved: 49960
            },
            image: "/image/geniamplan3.svg",
            background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
            color: "#F29912"
        },
        {
            name: "HXエキスパート",
            subName1: "ソリューションを創ってHX支援",
            subName2: "経営者、法人向けの",
            subName3: "HX事業を伸ばす",
            id: "prod_Kp7BmTB7K1vSpO",
            price: {
                month: "price_1K9SwqKtz9s4YUGtp3VEGwGU",
                year: "price_1K9SwqKtz9s4YUGtbJ82izIC"
            },
            amount: {
                month: 14980,
                year: 149800,
                coinsPlus: 50000,
                saved: 29600,
                totalSaved: 79600,
            },
            image: "/image/geniamplan4.svg",
            background: "linear-gradient(91.53deg, rgb(238,118,59) -0.82%, rgb(235,51,36) 99.49%)",
            color: "rgb(235,51,36)"
        },
    ]
    :
    [
        {
            name: "HXプロフェッショナル",
            subName1: "ツールを使ってHX支援",
            subName2: "Live配信と録画で",
            subName3: "１人でいつでも学べる",
            id: "prod_Kp0lgoc1ypEZcs",
            price: {
                month: "price_1K9WorKtz9s4YUGtx3zi4OHE",
                year: "price_1K9MjWKtz9s4YUGt68qRQ4Y8"
            },
            amount: {
                month: 4980,
                year: 49800,
                coinsPlus: 10000,
                saved: 9960,
                totalSaved: 19960,
            },
            image: "/image/geniamplan2.svg",
            background: "linear-gradient(91.53deg, #9F2AFB -0.82%, #DF2D6D 99.49%)",
            color: "#9F2AFB"
        },
        {
            name: "HXスペシャリスト",
            subName1: "ツールを使ってHX支援",
            subName2: "1on1の実践と対話で",
            subName3: "仲間と切磋琢磨できる",
            id: "prod_Kp1doQSIKZVWsp",
            price: {
                month: "price_1K9NZuKtz9s4YUGtl3JBNAtX",
                year: "price_1K9NZuKtz9s4YUGtj5zdKc8E"
            },
            amount: {
                month: 9980,
                year: 99800,
                coinsPlus: 30000,
                saved: 19960,
                totalSaved: 49960
            },
            image: "/image/geniamplan3.svg",
            background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
            color: "#F29912"
        },
        {
            name: "HXエキスパート",
            subName1: "ソリューションを創ってHX支援",
            subName2: "経営者、法人向けの",
            subName3: "HX事業を伸ばす",
            id: "prod_Kp1esAcpRs85yb",
            price: {
                month: "price_1K9NatKtz9s4YUGthUokiJtb",
                year: "price_1K9NatKtz9s4YUGt0Zm00d8n"
            },
            amount: {
                month: 14980,
                year: 149800,
                coinsPlus: 50000,
                saved: 29600,
                totalSaved: 79600,
            },
            image: "/image/geniamplan4.svg",
            background: "linear-gradient(91.53deg, rgb(238,118,59) -0.82%, rgb(235,51,36) 99.49%)",
            color: "rgb(235,51,36)"
        },
    ]


export const isListChange = (prevList, nextList) => {
    if (prevList.length !== nextList.length)
        return true
    let result = false
    prevList.forEach((oldvalue) => {
        let idx = findIndex(nextList, {id: oldvalue.id})
        if (idx === -1) {
            result = true
        }
        if (idx !== -1) {
            if (nextList[idx]?.title !== oldvalue?.title)
                result = true
            if (nextList[idx]?.limitUser !== oldvalue?.limitUser)
                result = true
            if (nextList[idx]?.name !== oldvalue?.name)
                result = true
            if (nextList[idx]?.moneyMinimum !== oldvalue?.moneyMinimum)
                result = true
            if (nextList[idx]?.image !== oldvalue?.image)
                result = true
        }
    })
    return result
}

export const isListChangeStream = (prevList, nextList) => {
    if (prevList.length !== nextList.length)
        return true
    let result = false
    prevList.forEach((oldvalue) => {
        let idx = findIndex(nextList, {id: oldvalue.id})
        if (idx === -1) {
            result = true
        }
        if (idx !== -1) {
            if (nextList[idx]?.joins?.length !== oldvalue?.joins?.length)
                result = true
            if (nextList[idx]?.unJoins?.length !== oldvalue?.unJoins?.length)
                result = true
            if (nextList[idx]?.titleRecurrenceList?.length !== oldvalue?.titleRecurrenceList?.length)
                result = true
            if (nextList[idx]?.product?.id !== oldvalue?.product?.id)
                result = true
            if (nextList[idx]?.limitUser !== oldvalue?.limitUser)
                result = true
            if (nextList[idx]?.moneyMinimum !== oldvalue?.moneyMinimum)
                result = true
            if (nextList[idx]?.start !== oldvalue?.start)
                result = true
        }
    })
    return result
}
