import {proxy} from 'valtio/vanilla';

const gemsProxy = proxy({
        gems: {},
        setGems: (gem) => {
            gemsProxy.gems = gem
        }
    }
)
export {
    gemsProxy
}
